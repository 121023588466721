@import "../shared";

.article-page {
  &__content {
    @include container();
    padding: 0 15px 20px;
    @media screen and (min-width: $viewPort-tablet) {
      padding: 0 50px 60px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      margin-top: -5vw;
      padding: 0 78px 80px;
    }

    .share {
      display: block;
      margin-bottom: 62px;
      @media screen and (min-width: $viewPort-tablet) {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
      }
      @media screen and (min-width: $viewPort-desktop) {
        margin-bottom: 46px;
      }
    }
  }

  &__column {
    max-width: 932px;
  }

  .sliders {
    @media screen and (min-width: $viewPort-desktop) {
      margin-left: -20px;
      padding-left: 20px;
    }

    .card {
      box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
    }

    .slick-slider__wrapper__counter .current {
      color: $green-400;
    }

    .slick-slider__wrapper__counter .total {
      color: $grey-500;
    }

    .slick-arrow {
      &:hover {
        &::before,
        &::after {
          color: $green-500;
        }
      }
    }

    .slick-dots {
      &::before {
        background-color: $grey-500;
      }

      li {
        button {
          &::before,
          &::after {
            background-color: $green-400;
          }
        }
      }
    }

    .heading {
      h2 {
        color: $grey-1000;

        @media screen and (min-width: $viewPort-desktop) {
          margin-left: 30px;
        }
      }
    }
  }
}
