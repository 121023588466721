@import "../../assets/scss/shared";

.article-promo {
  @include container();

  margin-bottom: 25.5%;
  color: $grey-1000;
  padding: 0;
  @media screen and (min-width: $viewPort-tablet) {
    margin-bottom: 17%;
    padding: 0;
  }
  @media screen and (min-width: $viewPort-desktop) {
    margin-bottom: 14.5%;
  }

  &__wrapper {
    @media screen and (min-width: $viewPort-desktop) {
      display: flex;
    }
  }

  &__content {
    padding: 44px 15px 60px;
    @media screen and (min-width: $viewPort-tablet) {
      padding: 49px 50px 100px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      padding: 88px 116px 48px 81px;
      flex: 0 0 61%;
    }
  }

  &__back-holder {
    margin: 0 0 29px;
    @media screen and (min-width: $viewPort-tablet) {
      margin: 0 0 50px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      margin: 0 34px 20px;
      min-height: 87px;
    }
  }

  &__subtitle {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: $green-500;
    display: block;
    font-weight: normal;
    margin: 0 0 12px;
    @media screen and (min-width: $viewPort-desktop) {
      margin: 0 0 8px;
    }
  }

  h2 {
    font-size: 22px;
    line-height: 1.3;
    color: $grey-1000;
    margin: 0 0 32px;
    @media screen and (min-width: $viewPort-tablet) {
      font-size: 28px;
      margin: 0 0 26px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      font-size: 32px;
      line-height: 1.3;
      margin: 0 0 18px;
    }
  }

  &__meta-holder {
    font-size: 18px;
    line-height: 1.25;
    font-weight: 300;
    letter-spacing: 0.01em;
    margin: 0 0 26px;
    @media screen and (min-width: $viewPort-tablet) {
      font-size: 22px;
      line-height: 1.5;
      margin: 0 0 20px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      margin: 0 0 13px;
    }

    time {
      display: block;
      margin: 0 0 8px;
      @media screen and (min-width: $viewPort-tablet) {
        display: inline;
        margin: 0 10px 0 0;
      }
    }

    .location {
      @media screen and (min-width: $viewPort-tablet) {
        margin-left: 0;
      }
    }
  }

  &__visual {
    position: relative;
    @media screen and (min-width: $viewPort-desktop) {
      flex: 0 0 39%;
    }

    .decoration-square {
      @include position($position: absolute, $top: -40px, $left: 20px);
      width: 80px;
      height: 80px;
      z-index: 2;
      opacity: 0.6;
      @media screen and (min-width: $viewPort-tablet) {
        width: 120px;
        height: 120px;
        top: -56px;
        left: 50px;
      }
      @media screen and (min-width: $viewPort-desktop) {
        top: 47%;
        left: -60px;
        transform: translateY(-50%);
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      @media screen and (min-width: $viewPort-desktop) {
        @include position($position: absolute, $top: 0%, $left: 0);
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__description {
    max-width: 517px;
  }

  .decor-line {
    @include decor-sloping-line($grey-500);
    position: relative;
    margin-top: -41px;
    z-index: 2;
    @media screen and (min-width: $viewPort-tablet) {
      margin-top: -55px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      margin-top: 10px;
    }
    &:after {
      content: "";
      @include position(
        $position: absolute,
        $top: 0,
        $bottom: 0,
        $left: -99999px,
        $right: -99999px
      );
      background-color: $grey-500;
    }
    &:before {
      content: "";
      @include position($position: absolute, $top: 100%, $left: 0);
      height: 50px;
      width: 100%;
      background-color: $white;
      @media screen and (min-width: $viewPort-tablet) {
        height: 60px;
      }
    }
  }
}
