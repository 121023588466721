@import "../../../assets/scss/shared";

.MixedContent {
  display: flex;
  justify-content: flex-start;
  width: calc(100% + 180px);
  margin: 10px 0px;
  padding: 10px 0px;
  flex-flow: column wrap;

  @media screen and (min-width: $viewPort-tablet) {
    flex-direction: row;
  }
}
