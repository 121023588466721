@import "../../assets/scss/shared";

.testimonials-list {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $viewPort-tablet) {
    flex-direction: row;
    justify-content: space-between;
  }

  li {
    margin-bottom: 34px;

    @media screen and (min-width: $viewPort-tablet) {
      max-width: 30%;
    }

    a {
      @include links($green-200);

      display: block;
      margin-bottom: 24px;
      font-weight: 600;
      font-size: 22px;
      line-height: 1.5;

      &:hover {
        text-decoration: underline;
      }
    }

    p {
      margin: 0;
      font-weight: 300;
      font-size: 22px;
      line-height: 1.5;
      color: $white;
      mix-blend-mode: normal;
      opacity: 0.68;
    }
  }
}
