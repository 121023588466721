@import "../../../assets/scss/_shared.scss";

.link-default {
  @include btn();

  display: inline-block;
  position: relative;
  overflow: hidden;
  padding: 16px 14px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  border: 3px solid $green-300;
  background-color: $green-300;
  color: $grey-3;
  transition: border 0.3s ease, background 0.3s ease, color 0.3s ease;
  white-space: nowrap;

  .text {
    position: relative;
    z-index: 1;
  }

  &::before {
    content: "";
    @include position($position: absolute, $top: 0, $left: 0);
    height: 100%;
    background-color: $white;
    width: 0;
    opacity: 0;
    transition: width 0.3s ease, opacity 0.3s ease;
  }

  &::after {
    content: "⟶";
    margin-left: 44px;
    font-size: 18px;
    transition: color 0.3s ease;
    position: relative;
    z-index: 1;
  }

  &:hover {
    text-decoration: none;
    &::before {
      width: 100%;
      opacity: 1;
    }
  }

  &.has-white-border {
    border-color: $white;
    background-color: transparent;
    color: $white;
    &::after {
      color: $green-300;
    }
    &::before {
      background-color: $green-300;
    }
    &:hover {
      border-color: $green-300;
      color: $grey-3;
      &:after {
        color: $grey-3;
      }
    }
  }
}
