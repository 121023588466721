@import "../../assets/scss/shared";
$svg-width: 60px;

.AmbassadorCard {
  @mixin card-text {
    margin: 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
  }

  display: flex;
  flex-direction: column;
  width: $photo-width;
  background-color: $white;
  filter: drop-shadow(0px 0px 46px rgba(0, 0, 0, 0.1));
  transition: 0.1s ease-in-out;
  &:hover {
    cursor: pointer;
    @media screen and (min-width: $viewPort-desktop) {
      transform: scale(1.1);
    }
  }

  &__thumbnail {
    object-fit: cover;
    height: $photo-width;
    width: $photo-width;
  }
  &__description {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 12px 16px 36px 16px;
  }

  &__graphic {
    position: absolute;
    height: $svg-width;
    width: $svg-width;
    top: -$svg-width / 2;
    right: 0;
  }

  &__text-title {
    @include card-text;

    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;

    letter-spacing: 0.02em;
    color: $green-600;
  }

  &__text-name {
    @include card-text;

    margin: 9px 0px 3px 0px;
    font-size: 18px;
    line-height: 150%;
    color: $black;
  }

  &__text-company {
    @include card-text;

    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;

    color: $black;
  }
}
