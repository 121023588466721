@import "../../assets/scss/shared";

.page-section.announcements_promo__holder,
.page-section.events_promo__holder,
.page-section.competitions_promo__holder,
.page-section.all_news_promo__holder {
  .container {
    max-width: 100%;
    padding: 0;
  }
}

.all-news-promo {
  padding-bottom: 120px;
  position: relative;
  overflow: hidden;
  @media screen and (min-width: $viewPort-tablet) {
    padding-bottom: 170px;
  }
  @media screen and (min-width: $viewPort-desktop) {
    padding-bottom: 0;
  }
  &::before {
    content: "";
    @include position(
      $position: absolute,
      $left: -9999px,
      $right: -9999px,
      $bottom: 0
    );
    height: 10px;
    margin-bottom: -9999px;
    padding-bottom: 9999px;
    background-color: $green-600;
    transform: rotate(-7deg);
    @media screen and (min-width: $viewPort-tablet) {
      bottom: 20px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      z-index: 2;
      bottom: 40px;
      transform: rotate(-6deg);
    }
  }

  &__frame {
    @media screen and (min-width: $viewPort-desktop) {
      @include container();
      padding: 0;

      display: flex;
      flex-direction: row-reverse;
    }
  }

  &__visual {
    position: relative;
    overflow: hidden;
    z-index: 2;
    @media screen and (min-width: $viewPort-desktop) {
      flex: 0 0 39%;
      overflow: visible;
      z-index: 1;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;
      @media screen and (min-width: $viewPort-desktop) {
        @include position($position: absolute, $left: 0, $top: 0);

        height: 90%;
      }
    }

    .decoration-square {
      width: 85px;
      height: 85px;
      @include position($position: absolute, $left: 80px, $bottom: -48px);
      opacity: 0.8;
      @media screen and (min-width: $viewPort-tablet) {
        width: 120px;
        height: 120px;
        bottom: -52px;
      }
      @media screen and (min-width: $viewPort-desktop) {
        width: 120px;
        height: 120px;
        left: -60px;
        bottom: auto;
        top: 38%;
        opacity: 0.6;
      }
    }
  }

  &__content {
    padding: 158px 15px 0;
    position: relative;
    @media screen and (min-width: $viewPort-tablet) {
      padding: 275px 50px 0;
    }
    @media screen and (min-width: $viewPort-desktop) {
      flex: 0 0 61%;
      padding: 275px 100px 150px 120px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 60px;
      height: 10px;
      margin-top: -9999px;
      padding-top: 9999px;
      left: -9999px;
      right: -9999px;
      background-color: $green-300;
      transform: rotate(-7deg);
      @media screen and (min-width: $viewPort-tablet) {
        top: 75px;
        transform: rotate(-9deg);
      }
    }

    h2 {
      margin: 0 0 23px;
      @media screen and (min-width: $viewPort-tablet) {
        max-width: 517px;
        margin: 0 0 15px;
      }
      @media screen and (min-width: $viewPort-desktop) {
        font-size: 32px;
        line-height: 1.3;
      }
    }

    .description {
      margin: 0 0 25px;
      @media screen and (min-width: $viewPort-tablet) {
        max-width: 517px;
      }
    }
  }

  &__link {
    text-align: right;
    padding-right: 20px;
    @media screen and (min-width: $viewPort-desktop) {
      padding-right: 0;
    }
  }

  &__meta-holder {
    font-size: 18px;
    line-height: 1.25;
    font-weight: 300;
    letter-spacing: 0.01em;
    margin: 0 0 26px;
    @media screen and (min-width: $viewPort-tablet) {
      font-size: 22px;
      line-height: 1.5;
      margin: 0 0 20px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      margin: 0 0 13px;
    }

    time {
      display: block;
      margin: 0 0 8px;
      @media screen and (min-width: $viewPort-tablet) {
        display: inline;
        margin: 0 10px 0 0;
      }
    }

    .location {
      @media screen and (min-width: $viewPort-tablet) {
        margin-left: 0;
      }
    }
  }
}
