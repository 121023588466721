@import "../../assets/scss/shared";

.step-container {
  .faq {
    padding-top: 0;
    padding-bottom: 0;

    @media screen and (min-width: $viewPort-desktop) {
      padding: 0 0 0 79px;

      ul.faq-list {
        margin-bottom: 50px;
      }
    }

    &__title-group {
      h2 {
        max-width: 239px;
        font-size: 22px;
        line-height: 1.3;
        letter-spacing: 0.01em;
        color: $green-500;

        @media screen and (min-width: $viewPort-tablet) {
          max-width: 513px;
          font-size: 28px;
        }

        @media screen and (min-width: $viewPort-desktop) {
          max-width: 100%;
          font-size: 32px;
        }
      }

      a {
        flex-shrink: 0;
        margin-top: 17px;
      }
    }
  }

  .share,
  .share-socials {
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 40px;
    @media screen and (min-width: $viewPort-tablet) {
      padding: 0 50px;
      display: flex;
    }
    @media screen and (min-width: $viewPort-desktop) {
      padding: 0 0 0 79px;
      margin-bottom: 60px;
    }
  }
}
