@import "../../assets/scss/shared";

.get-involved {
  @media screen and (min-width: $viewPort-tablet) {
    display: flex;
    justify-content: space-between;
  }

  & > li {
    position: relative;

    @media screen and (min-width: $viewPort-tablet) {
      flex-basis: 50%;
      height: 362px;

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    &::before,
    &::after {
      content: "";
      @include position($position: absolute, $top: 0, $left: 0);
      width: 100%;
      height: 100%;
    }

    &::before {
      background: radial-gradient(
        52.29% 124.03% at 71.25% 0%,
        rgba(4, 19, 32, 0.5) 0%,
        rgba(16, 47, 58, 0) 100%
      );
    }

    &::after {
      background: radial-gradient(
        72.52% 97.87% at 21.93% 100%,
        rgba(4, 19, 32, 0.88) 0%,
        rgba(16, 47, 58, 0) 100%
      );
    }

    & > span {
      @include position($position: absolute, $top: 0, $left: 0);
      width: 100%;
      height: 100%;
      background: linear-gradient(
        359.17deg,
        rgba(4, 19, 32, 0.81) 1.29%,
        rgba(23, 60, 68, 0) 99.02%
      );
    }

    img {
      display: block;
    }

    .text-group {
      @include position($position: absolute, $bottom: 0, $left: 0);
      width: 100%;
      padding: 0 60px 51px 35px;
      z-index: 1;

      strong,
      a {
        color: $white;
      }

      strong {
        display: block;
        margin-bottom: 29px;
        font-size: 32px;
        line-height: 1.3;
        letter-spacing: 0.01em;
      }

      a {
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.5;
        text-decoration: none;
        text-align: right;

        span {
          display: inline-block;
          margin-left: 28px;
        }
      }
    }
  }

  .decor-line {
    @include decor-sloping-line($green-500);
  }
}
