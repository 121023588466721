@import "../../assets/scss/shared";

.card {
  width: 100%;
  position: relative;
  color: $green-600;
  line-height: 24px;

  &:after {
    content: "";
    box-shadow: 1px 0 #bfc2c4, 1px -1px #bfc2c4, 2px -1px #bfc2c4,
      2px -2px #bfc2c4, 3px -2px #bfc2c4, 3px -3px #bfc2c4, 4px -3px #bfc2c4,
      4px -4px #bfc2c4, 5px -4px #bfc2c4, 5px -5px #bfc2c4, 6px -5px #bfc2c4,
      6px -6px #bfc2c4, 7px -6px #bfc2c4, 7px -7px #bfc2c4, 8px -7px #bfc2c4,
      8px -8px #bfc2c4, 9px -8px #bfc2c4, 9px -9px #bfc2c4, 10px -9px #bfc2c4,
      10px -10px #bfc2c4, 11px -10px #bfc2c4, 11px -11px #bfc2c4,
      12px -11px #bfc2c4, 12px -12px #bfc2c4;
    @include position($position: absolute, $left: 0, $top: 0);
    width: 100%;
    height: 100%;
    transform: scale(0.9);
    transform-origin: left bottom;
    transition: transform 0.5s;
  }

  &:hover {
    &:after {
      @media screen and (min-width: $viewPort-desktop) {
        transform: scale(1);
      }
    }
  }

  &__holder {
    background-color: $white;
    position: relative;
    z-index: 1;
    height: 520px;
    padding: 0 0 0 30px;

    &::after {
      content: "";
      @include position($position: absolute, $right: 0, $bottom: 0);
      width: 0;
      height: 0;
      border-color: transparent transparent $green-400;
      border-style: solid;
      border-width: 0 0 30px 30px;
    }

    &::before {
      content: "";
      @include position($position: absolute, $top: 0, $right: 30px);
      bottom: 0;
      width: 1px;
      background-color: $grey-2;
      z-index: 2;
      opacity: 0.4;
      pointer-events: none;
    }
  }

  &__visual {
    margin: 0 0 30px;
    position: relative;
    height: 245px;
    overflow: hidden;

    img {
      @include position($position: absolute, $top: 0, $left: 0);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: top 0.3s ease, left 0.3s ease, width 0.3s ease,
        height 0.3s ease;
    }

    .decoration-square {
      @include position($position: absolute, $bottom: 0, $right: 0);
      z-index: 2;
      opacity: 0.6;
      pointer-events: none;
    }

    &:hover {
      @media screen and (min-width: $viewPort-desktop) {
        img {
          top: -3%;
          left: -3%;
          width: 106%;
          height: 106%;
        }
      }
    }
  }

  &__description {
    padding-right: 60px;

    &__content {
      height: 94px;
      position: relative;
      overflow: hidden;
      margin: 0 0 14px;
    }
  }

  &__date {
    font-size: 12px;
    line-height: 15px;
    margin: 0 0 9px;
  }

  h4 {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.7;
    color: $grey-1000;
    position: relative;
    overflow: hidden;
    height: 54px;
    margin: 0 0 15px;

    @media screen and (min-width: $viewPort-desktop) {
      font-size: 18px;
      line-height: 1.5;
    }
  }

  &.card-video {
    color: $white;
    font: 16px/24px Helvetica, Arial, sans-serif;

    .card {
      &__holder {
        background-color: $green-900;
      }

      &__visual {
        background-color: $green-900;

        &.has-link {
          &:after {
            content: "▶";
            @include position($position: absolute, $bottom: 0, $left: 0);
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $green-300;
            background: rgba(71, 135, 142, 0.28);
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
            transition: all 0.3s ease;
          }

          &:hover {
            @media screen and (min-width: $viewPort-desktop) {
              &:after {
                width: 65px;
                height: 65px;
                font-size: 17px;
                color: $white;
              }
            }
          }
        }
      }

      &__description {
        &__content {
          height: 96px;
          margin: 0 0 12px;
        }
      }
    }

    h4 {
      font-size: 18px;
      line-height: 1.5;
      color: $white;
    }

    .link-secondary {
      color: $green-300;
    }
  }

  &.card-events {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;

    .card {
      &__visual {
        margin: 0 0 35px;
        height: 310px;
      }

      &__holder {
        padding-left: 0;
      }

      &__description {
        padding-left: 35px;

        &__content {
          height: 50px;
          margin: 0 0 3px;
        }
      }
    }

    h4 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: auto;
      margin: 0 0 3px;
    }
  }
}
