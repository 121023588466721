@import "../../assets/scss/shared";

.Marker {
  position: absolute;

  &__infobox {
    width: 196px;
    position: absolute;
    transform: translate(0%, -50%);
    right: 170%;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;

    &-container {
      position: relative;
      background-color: white;
      border-radius: 4px;
      padding: 23px 13.5px;
    }

    &-close-icon {
      position: absolute;
      top: 7px;
      right: 7px;
      cursor: pointer;
      color: #979797;
    }

    &-title {
      font-weight: 600;
    }

    &-maps-link {
      color: $red-500;
      font-size: 14px;
      line-height: 170%;
    }

    &-triangle {
      content: "";
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;

      border-left: 8px solid white;
    }
  }

  &__icon {
    transform: translate(-50%, -50%);
    height: 30px;
    // z-index: 10;
    cursor: pointer;
    color: #ff005d;
  }
}
