@import "../../assets/scss/shared";

.info-header {
  background-color: $hospFood;
  padding: 45px 999px;
  margin: 0 -999px 569px;
  position: relative;
  transform: rotate(-6deg);
  color: $white;
  @media screen and (min-width: $viewPort-tablet) {
    padding-top: 55px;
    padding-bottom: 55px;
    margin-bottom: 798px;
  }
  @media screen and (min-width: $viewPort-desktop) {
    padding-top: 75px;
    padding-bottom: 75px;
    margin-bottom: 130px;
  }
  &::before {
    content: "";
    @include position($position: absolute, $top: 0, $left: 0);
    width: 100%;
    height: 45px;
    z-index: 2;
    background-color: $grey-500;
    @media screen and (min-width: $viewPort-tablet) {
      height: 55px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      top: -1px;
      height: 76px;
    }
  }

  .h6,
  h1,
  h2,
  h3,
  h6 {
    color: $white;
  }

  &__frame {
    position: relative;
    margin: 0 -9999px;
    padding: 0 9999px;
    @media screen and (min-width: $viewPort-desktop) {
      overflow: hidden;
    }
    &::after {
      content: "";
      @include position($position: absolute, $top: 175px, $bottom: 0, $left: 0);
      width: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      @media screen and (min-width: $viewPort-tablet) {
        top: 190px;
      }
      @media screen and (min-width: $viewPort-desktop) {
        top: 145px;
      }
    }
  }

  &__holder {
    @include container();
    position: relative;
    z-index: 2;
    transform: rotate(6deg);
    @media screen and (min-width: $viewPort-tablet) {
      padding: 0 50px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      display: flex;
      padding: 0 80px;
      margin-top: -80px;
      margin-bottom: -10px;
    }
  }

  &__col-text {
    padding: 82px 0 46px;
    @media screen and (min-width: $viewPort-tablet) {
      padding: 115px 0 93px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      width: 100%;
      padding: 194px 120px 42px 0;
    }
    &::after {
      content: "";
      display: block;
      clear: both;
    }

    h6 {
      margin: 0 0 7px;
      @media screen and (min-width: $viewPort-desktop) {
        margin: 0 0 10px;
      }
    }

    h2 {
      font-size: 28px;
      line-height: 1.3;
      max-width: 517px;
      margin: 0 0 10px;
      @media screen and (min-width: $viewPort-tablet) {
        margin: 0 0 6px;
      }
      @media screen and (min-width: $viewPort-desktop) {
        font-size: 32px;
        margin: 0 0 9px;
      }
    }
  }

  &__link-back {
    min-height: 189px;
    margin: 0 0 20px;
    @media screen and (min-width: $viewPort-tablet) {
      min-height: 180px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      min-height: 142px;
    }
  }

  &__content {
    max-width: 517px;
    margin: 0 0 19px;
    @media screen and (min-width: $viewPort-tablet) {
      margin: 0 0 9px;
    }
  }

  .link-double {
    float: right;
  }

  &__col-image {
    @include position($position: absolute, $top: 100%, $left: 0, $right: 0);
    margin: 45px -999px 0;
    padding: 0 999px;
    transform: rotate(-6deg);
    overflow: hidden;
    @media screen and (min-width: $viewPort-tablet) {
      margin-top: 55px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      transform: rotate(0deg);
      position: relative;
      top: auto;
      flex: 578px 0 0;
      min-width: 578px;
      margin: 0 -85px 0 0;
      padding: 0;
      height: auto;
      overflow: visible;
    }

    picture {
      @include position($position: absolute, $top: 0, $left: 0);
      width: 100%;
      height: 100%;

      &::before {
        content: "";
        @include position($position: absolute, $top: 0, $left: 0);
        width: 100%;
        height: 100%;
        background: radial-gradient(
          169.73% 160.4% at 0% 92.67%,
          rgba(4, 19, 32, 0.6) 0%,
          rgba(4, 19, 32, 0) 100%
        );
        transform: matrix(-1, 0, 0, 1, 0, 0);
        z-index: 1;
      }
    }

    img {
      @include position($position: absolute, $top: 0, $left: 0);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__col-image-holder {
    height: 571px;
    margin: -40px 0;
    transform: rotate(6deg);
    position: relative;
    @media screen and (min-width: $viewPort-tablet) {
      height: 780px;
      margin: -56px 0;
    }
    @media screen and (min-width: $viewPort-desktop) {
      transform: rotate(0deg);
      height: 100%;
      margin: 0;
    }

    .decoration-letter {
      width: 81px !important;
      height: 135px !important;
      z-index: 2;
      @include position(
        $position: absolute,
        $top: auto,
        $bottom: 92px,
        $right: 30px
      );
      @media screen and (min-width: $viewPort-tablet) {
        width: 121px !important;
        height: 202px !important;
        right: 48px;
        bottom: 143px;
      }
      @media screen and (min-width: $viewPort-desktop) {
        right: 32px;
        bottom: 128px;
      }
    }

    .decoration-square {
      display: none;

      @media screen and (min-width: $viewPort-desktop) {
        display: block;
        @include position($position: absolute, $top: 49%, $left: -55px);
        opacity: 0.7;
      }
    }
  }
}
