@import "../../assets/scss/shared";
@import "./LearnMorePage.scss";

.next-steps {
  .two-columns {
    .link-holder {
      margin: 0px 0 40px;
      @media screen and (min-width: $viewPort-tablet) {
        margin: 0px 0 50px;
      }
      @media screen and (min-width: $viewPort-desktop) {
        margin: 0px 0 100px;
      }
    }
  }
}
