@import "../../assets/scss/shared";

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;

  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    background: rgba(4, 19, 32, 0.9);
    z-index: -1;
  }

  &__content {
    margin-top: 80px;
    overflow: visible;
    display: inline-block;
    justify-content: center;
    width: 90%;
    height: 90%;
    top: 8%;

    @media screen and (min-width: $viewPort-desktop) {
      max-width: 1088px;
      max-height: 672px;
    }
  }

  &__close-button {
    @include btn();
    align-self: flex-end;
    margin: 0px;
    padding: 14px;
    font-weight: 300;
    font-size: 30px;
    line-height: 150%;
    color: $white;
    background-color: transparent;
    transition: transform 0.3s ease;
    transform: rotate(0deg);
    position: absolute;
    top: -6px;
    right: 1%;

    &:hover {
      transform: rotate(90deg);
    }
    @media screen and (min-width: $viewPort-desktop) {
      right: 4%;
    }
  }
}
