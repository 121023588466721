@font-face {
  font-family: "Poppins";
  font-weight: 300;
  font-display: auto;
  src: local("Poppins-Light"), url("../fonts/Poppins-Light.eot"),
    url("../fonts/Poppins-Light.eot?#iefix") format("eot"),
    url("../fonts/Poppins-Light.woff2") format("woff2"),
    url("../fonts/Poppins-Light.woff") format("woff"),
    url("../fonts/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-display: auto;
  src: local("Poppins-Regular"), url("../fonts/Poppins-Regular.eot"),
    url("../fonts/Poppins-Regular.eot?#iefix") format("eot"),
    url("../fonts/Poppins-Regular.woff2") format("woff2"),
    url("../fonts/Poppins-Regular.woff") format("woff"),
    url("../fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 600;
  font-display: auto;
  src: local("Poppins-SemiBold"), url("../fonts/Poppins-SemiBold.eot"),
    url("../fonts/Poppins-SemiBold.eot?#iefix") format("eot"),
    url("../fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("../fonts/Poppins-SemiBold.woff") format("woff"),
    url("../fonts/Poppins-SemiBold.ttf") format("truetype");
}
