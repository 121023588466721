@import "../../assets/scss/shared";

.all-news-articles {
  background-color: $green-600;
  margin: 0 -9999px;
  padding: 82px 9999px 60px;

  .all-news-articles-list {
    @media screen and (min-width: $viewPort-tablet) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 -15px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      justify-content: start;
    }

    .lazyload-placeholder {
      min-height: 520px;
      position: relative;
      background-color: $white;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 150px;
        height: 150px;

        background: url(../../assets/img/loader/loader.svg) no-repeat 50%;
        background-size: 100% 100%;
        transform: translate(-50%, -50%);
        z-index: 2;
      }
    }

    .lazyload-wrapper {
      margin: 0 auto 30px;
      max-width: 330px;
      min-width: 300px;
      @media screen and (min-width: $viewPort-tablet) {
        flex: 0 0 calc(50% - 30px);
        margin: 0 15px 50px;
      }
      @media screen and (min-width: $viewPort-desktop) {
        flex: 0 0 calc(33.3333% - 30px);
        margin-bottom: 35px;
      }
    }
  }

  .nothing-found-box {
    margin-top: 50px;
    @media screen and (min-width: $viewPort-desktop) {
      margin-top: 95px;
    }

    h3 {
      color: $white;
      font-weight: 300;
      margin: 0 0 29px;
      @media screen and (min-width: $viewPort-tablet) {
        margin-bottom: 36px;
      }
      @media screen and (min-width: $viewPort-desktop) {
        margin-bottom: 42px;
      }

      strong {
        font-weight: bold;
      }
    }
  }
}

.page-section.announcements__holder,
.page-section.events__holder,
.page-section.competitions__holder,
.page-section.all_news__holder {
  padding: 0;
}
