@import "../../assets/scss/shared";

.published-on {
  font-size: 12px;
  line-height: 1.25;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  list-style: none;
  padding: 0;
  margin: 0 0 25px;
  display: flex;
  @media screen and (min-width: $viewPort-tablet) {
    margin: 0 0 35px;
  }
  @media screen and (min-width: $viewPort-desktop) {
    margin: 0 0 24px;
  }

  dd {
    margin: 0 0 0 6px;
  }
}

.article-text {
  position: relative;

  @media screen and (min-width: $viewPort-desktop) {
    padding-right: 180px;
  }

  p {
    min-height: calc(1.68 * 1em); // so that empty paragraphs take at least some space
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $black;
  }

  blockquote,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0 0 10px;
  }

  ul {
    list-style: outside;
    margin: 0 0 16px 18px;
    @media screen and (min-width: $viewPort-tablet) {
      margin-bottom: 20px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      margin-bottom: 27px;
    }

    p {
      margin: 0;
    }
  }

  img {
    display: block;
    margin: 25px 0 20px;
    @media screen and (min-width: $viewPort-desktop) {
      width: calc(100% + 180px);
    }
  }
}
