@import "../../assets/scss/shared";

.faq_section__holder {
  .page-section_head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      margin-top: 25px;
      margin-bottom: 15px;
    }
  }
}

.faq-list {
  margin-bottom: 50px;

  @media screen and (min-width: $viewPort-tablet) {
    margin-bottom: 60px;
  }

  .faq-list-item:not(:first-of-type) {
    margin-top: 20px;
  }

  &__item-question {
    background-color: transparent;
    position: relative;
    color: $white;
    transition: background-color 0.5s;
    display: block;
    border: none;
    cursor: pointer;
    width: 100%;
    text-align: left;
    padding: 14px 96px 14px 17px;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.25;
    letter-spacing: 0.01em;
    user-select: none;
    @media screen and (min-width: $viewPort-tablet) {
      font-size: 22px;
      line-height: 1.5;
    }
    @media screen and (min-width: $viewPort-desktop) {
      padding: 24px 96px 23px 31px;
    }
    &::before,
    &::after {
      content: "";
      @include position($position: absolute, $top: 0, $left: 0);
      width: 100%;
      height: 100%;
    }
    &::before {
      z-index: -2;
      @include bg-color($green-500);
    }
    &::after {
      width: 0;
      opacity: 0;
      z-index: -1;
      @include bg-color($green-600);
      transition: opacity 0.3s ease, width 0.3s ease;
    }
    &:hover {
      &::after {
        width: 100%;
        opacity: 1;
      }
    }

    &.open {
      &::after {
        width: 100%;
        opacity: 1;
      }
      .indicator {
        background-color: $green-500;
        &:before {
          transform: rotate(0deg);
        }
      }
    }

    .indicator {
      @include position($position: absolute, $top: 0, $right: 0);
      background-color: $green-600;
      width: 45px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background 0.3s ease;
      &:after,
      &:before {
        content: "";
        width: 22px;
        height: 4px;
        background-color: $white;
        @media screen and (min-width: $viewPort-desktop) {
          width: 38px;
          height: 6px;
        }
      }
      &:before {
        transform: rotate(90deg);
        transition: transform 0.3s ease;
      }
      &:after {
        @include position($position: absolute, $top: 50%, $left: 50%);
        transform: translate(-50%, -50%);
        z-index: 2;
      }
      @media screen and (min-width: $viewPort-tablet) {
        width: 60px;
      }
      @media screen and (min-width: $viewPort-desktop) {
        width: 80px;
      }
    }
  }

  &__item-answer {
    font-size: 16px;
    line-height: 1.7;
    max-height: 0;
    overflow: hidden;
    position: relative;
    transition: max-height 0.3s ease;

    p {
      margin: 0 0 27px;
      &:last-child {
        margin: 0;
      }
    }
  }

  &__holder {
    padding: 30px 16px 15px;
    @media screen and (min-width: $viewPort-desktop) {
      display: flex;
    }
  }

  &__content {
    width: 100%;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: normal;
      margin: 0 0 30px;
    }

    ul {
      list-style: outside;
      margin-left: 18px;
      margin: 0 0 16px 18px;
      @media screen and (min-width: $viewPort-tablet) {
        margin-bottom: 20px;
      }
      @media screen and (min-width: $viewPort-desktop) {
        margin-bottom: 27px;
      }

      li {
        margin: 0;
      }

      p {
        margin: 0;
      }
    }
  }
}
