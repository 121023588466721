@import "../../assets/scss/shared";

.steppers-sub-nav {
  font-size: 16px;
  line-height: 1.7;
  font-weight: normal;
  margin: -29px 0 -14px;
  padding: 0 17px;
  @media screen and (min-width: $viewPort-tablet) {
    margin: 12px 0 4px;
    padding: 0;
  }
  @media screen and (min-width: $viewPort-desktop) {
    margin: -78px -15px -6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__holder {
    color: inherit;
    text-decoration: none !important;
  }

  &__count,
  &__title {
    font-size: 18px;
    line-height: 1.25;
    font-weight: 600;
    letter-spacing: 0.01em;
    @media screen and (min-width: $viewPort-tablet) {
      font-size: 22px;
      line-height: 1.5;
    }
  }

  &__title {
    display: flex;
    text-transform: uppercase;
    margin: 0 0 16px;
    @media screen and (min-width: $viewPort-desktop) {
      margin: 0 0 13px;
    }
  }

  li {
    opacity: 0.6;
    transition: opacity 0.3s ease;
    @media screen and (min-width: $viewPort-desktop) {
      max-width: 341px;
      padding: 0 15px;
    }

    &.current-active {
      opacity: 1;
      font-weight: 500;

      .steppers-sub-nav__description {
        margin: 0 0 24px;
      }

      .decoration-line {
        display: block;
        height: 7px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 46px;
      @media screen and (min-width: $viewPort-tablet) {
        margin-bottom: 69px;
      }
      @media screen and (min-width: $viewPort-desktop) {
        margin-bottom: 0;
      }
    }
  }

  .clickable {
    cursor: pointer;
  }
}
