@import "../../assets/scss/shared";

.main-footer {
  @include bg-color($green-600);
  position: relative;
  z-index: 1;

  .changePreferences {
    background: none;
    border: none;
    @include links($white);
    @include hoverLinks();
    &:hover {
      cursor: pointer;
    }
  }

  .top-line {
    @media screen and (min-width: $viewPort-tablet) {
      background-color: $green-600;
    }

    .container {
      @include container();
      padding: 36px 15px 31px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (min-width: $viewPort-tablet) {
        padding: 48px 35px 43px 15px;
      }

      @media screen and (min-width: $viewPort-desktop) {
        padding: 17px 67px 17px 60px;
      }
    }

    .back-to-top-btn {
      @include btn();
      @include links($green-300);
      @include hoverLinks();

      padding: 0 5px;
      background-color: transparent;

      @media screen and (min-width: $viewPort-tablet) {
        margin-bottom: 0;
      }

      svg {
        margin-left: 15px;
      }
    }

    .share-socials {
      margin-top: 29px;
      color: $white;

      @media screen and (min-width: $viewPort-tablet) {
        display: flex;
        align-items: center;
      }

      strong {
        display: block;
        margin-bottom: 5px;
        line-height: 21px;

        @media screen and (min-width: $viewPort-tablet) {
          display: inline-block;
          margin: 0 8px 0 0;
        }
      }

      .social-networks__list {
        justify-content: space-between;

        & > li {
          font-size: 24px;
        }
      }
    }
  }

  .footer-logos {
    @include bg-color($white);

    &-list {
      @include container();

      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 22px 0 15px 15px;

      @media screen and (min-width: $viewPort-tablet) {
        padding: 26px 0 30px 60px;
        margin-bottom: -30px;
      }

      @media screen and (min-width: $viewPort-desktop) {
        padding: 45px 57px 40px;
        margin-bottom: 0;
      }

      li {
        margin-bottom: 7px;

        @media screen and (min-width: $viewPort-tablet) {
          margin-bottom: 30px;
        }

        @media screen and (min-width: $viewPort-desktop) {
          margin-bottom: 0;
        }

        &:not(:last-child) {
          margin-right: 7px;

          @media screen and (min-width: $viewPort-tablet) {
            margin-right: 14px;
          }
        }

        &:nth-child(1) {
          img {
            width: 77px;

            @media screen and (min-width: $viewPort-tablet) {
              width: 157px;
            }

            @media screen and (min-width: $viewPort-desktop) {
              width: 147px;
            }
          }
        }

        &:nth-child(2) {
          img {
            width: 134px;

            @media screen and (min-width: $viewPort-tablet) {
              width: 199px;
            }

            @media screen and (min-width: $viewPort-desktop) {
              width: 271px;
            }
          }
        }

        &:nth-child(3) {
          img {
            width: 121px;

            @media screen and (min-width: $viewPort-tablet) {
              width: 170px;
            }

            @media screen and (min-width: $viewPort-desktop) {
              width: 271px;
            }
          }
        }

        &:nth-child(4) {
          align-self: flex-start;

          @media screen and (min-width: 499px) {
            align-self: unset;
          }

          @media screen and (min-width: $viewPort-tablet) {
            order: 1;
          }

          @media screen and (min-width: $viewPort-desktop) {
            order: 0;
          }

          img {
            width: 124px;

            @media screen and (min-width: $viewPort-tablet) {
              width: 227px;
            }

            @media screen and (min-width: $viewPort-desktop) {
              width: 308px;
            }
          }
        }

        &:nth-child(5) {
          img {
            width: 93px;

            @media screen and (min-width: $viewPort-tablet) {
              width: 244px;
            }

            @media screen and (min-width: $viewPort-desktop) {
              width: 227px;
            }
          }
        }
      }
    }
  }

  .about-us {
    @include bg-color($green-500);

    .container {
      @include container();

      padding: 32px 15px 24px;

      @media screen and (min-width: $viewPort-tablet) {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 40px 15px 48px;
      }

      @media screen and (min-width: $viewPort-desktop) {
        padding: 40px 57px;
      }

      p {
        color: $white;
      }
    }

    &__title {
      margin: 0 0 13px;

      @media screen and (min-width: $viewPort-tablet) {
        margin-bottom: 0;
      }

      a {
        @include links($white);
        @include hoverLinks();

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }

    .social-networks__list {
      margin-bottom: 13px;

      @media screen and (min-width: $viewPort-tablet) {
        margin-bottom: 0;
      }

      & > li {
        &:not(:last-child) {
          margin-right: 15px;

          @media screen and (min-width: $viewPort-tablet) {
            margin-right: 23px;
          }
        }

        @media screen and (min-width: $viewPort-tablet) {
          font-size: 32px;
        }
      }
    }

    &__copyright {
      max-height: 30px;
      margin: 0;

      @media screen and (min-width: $viewPort-tablet) {
        width: 100%;
      }

      @media screen and (min-width: 1025px) {
        width: auto;
        order: -1;
      }

      span {
        display: block;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.2em;
        text-transform: uppercase;

        @media screen and (min-width: $viewPort-tablet) {
          display: inline-block;

          &:not(:last-child) {
            padding-right: 5px;
          }
        }
      }
    }
  }
}
