@import "../../../assets/scss/_shared.scss";

.link-back {
  display: inline-block;
  font-size: 14px;
  line-height: 1.5;
  font-weight: normal;
  color: $green-500;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  outline: none;

  &::before {
    content: "⟵";
    margin: 0 28px 0 0;
    position: relative;
    transition: left 0.3s ease;
    left: 0;
  }

  &:hover {
    text-decoration: none;

    .text {
      text-decoration: underline;
    }

    &::before {
      left: -3px;
    }
  }
}
