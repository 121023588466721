@import "../../../assets/scss/shared";
$image-width: 280px;
$rti-margin: 2px;
$rti-padding: 24px;

.RichTextImage {
  display: flex;
  margin: $rti-margin;
  width: $image-width + $rti-padding + $rti-margin * 2;
  @media screen and (min-width: $viewPort-tablet) {
    padding-right: $rti-padding;
    width: $image-width + $rti-padding + $rti-margin * 2;
  }
  @media screen and (min-width: $viewPort-desktop) {
    padding-right: $rti-padding;
    width: $image-width + $rti-padding + $rti-margin * 2;
  }
  &__figure {
    margin: 0px;
    width: $image-width;
    justify-content: center;
  }

  &__image {
    // Prevent .article-text img override
    margin: 0px !important;
    width: $image-width !important;
    height: $image-width !important;
    object-fit: cover;
    @media screen and (min-width: $viewPort-tablet) {
      width: $image-width !important;
      height: $image-width;
      object-fit: cover;
    }
    @media screen and (min-width: $viewPort-desktop) {
      width: $image-width !important;
      height: $image-width;
      object-fit: cover;
    }
  }
  &__link {
    margin-left: 4px;
  }
  &__caption {
    font-family: Poppins;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: $green-700;
  }
}
