@import "../../../assets/scss/shared";

.skew-top-footer {
  height: 30px;
  background-color: $green-600;
  position: relative;
  margin-top: 30px;
  @media screen and (min-width: $viewPort-tablet) {
    height: 70px;
  }
  @media screen and (min-width: $viewPort-desktop) {
    margin-top: 100px;
  }
  &:after {
    @include skewLine($green-600);
    top: -40px;
    bottom: -9999px;

    @media screen and (min-width: $viewPort-desktop) {
      top: -120px;
    }
  }
}
