@import "../shared";

.container {
  width: 100%;
  padding: 0 15px;
  @media screen and (min-width: $viewPort-tablet) {
    padding: 0 50px;
  }
  @media screen and (min-width: $viewPort-desktop) {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 110px;
  }
}
