@import "../../assets/scss/shared";

.hiring-companies-list {
  margin-top: -60px;

  @media screen and (min-width: $viewPort-tablet) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: -40px -5px -5px;
  }

  @media screen and (min-width: $viewPort-desktop) {
    margin: -60px -10px -10px;
  }

  li {
    @media screen and (min-width: $viewPort-tablet) {
      padding: 5px;
    }

    @media screen and (min-width: $viewPort-desktop) {
      padding: 10px 10px;
    }

    img {
      width: 300px;
      height: auto;
      display: block;
      margin: 0 auto;
    }
  }
}
