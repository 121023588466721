@import "../../assets/scss/shared";

.section-title-block {
  .section-title__wrapper {
    min-height: 400px;

    @media screen and (min-width: $viewPort-desktop) {
      display: flex;
      justify-content: space-between;
      margin: 0 -110px;
    }

    .section-title {
      padding: 52px 0 54px;

      @media screen and (min-width: $viewPort-tablet) {
        padding: 60px 0 46px;
      }

      @media screen and (min-width: $viewPort-desktop) {
        width: 700px;
        max-width: 60%;
        padding: 48px 7px 48px 110px;

        &.no-image {
          width: 840px;
        }
      }

      @media screen and (max-width: 1480px) {
        margin-left: 0;
        margin-right: 0;
      }

      &.no-image {
        padding-right: 0;
      }

      h1 {
        font-size: 24px;
        line-height: 1.3;
        font-weight: bold;
        letter-spacing: 0.01em;
        margin: 0 0 20px;

        @media screen and (min-width: $viewPort-tablet) {
          font-size: 30px;
        }

        @media screen and (min-width: $viewPort-desktop) {
          font-size: 40px;
        }
      }

      p {
        margin-bottom: 20px;
        font-weight: 300;
        font-size: 18px;
        line-height: 1.25;
        letter-spacing: 0.01em;
        color: $green-600;
        margin-bottom: 43px;

        @media screen and (min-width: $viewPort-tablet) {
          font-size: 22px;
          line-height: 1.5;
        }
      }

      .title-note {
        b {
          float: left;
          font-size: 40px;
          line-height: 1.25;
          letter-spacing: 0.01em;
          margin-right: 10px;

          @media screen and (min-width: $viewPort-tablet) {
            font-size: 55px;
            line-height: 1.25;
            margin-top: 5px;
          }
        }

        span {
          font-weight: 300;
          font-size: 20px;
          line-height: 1.5;

          @media screen and (min-width: $viewPort-tablet) {
            font-size: 22px;
          }
        }
      }
    }

    .image__wrapper {
      position: relative;

      &--large {
        position: relative;
        clip-path: polygon(0 2%, 100% 1%, 100% 80%, 0 90%);

        @media screen and (max-width: $viewPort-desktop) {
          display: none;
        }

        flex: 0 0 39%;
        position: relative;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      @media screen and (min-width: $viewPort-desktop) {
        width: 50%;
      }

      .child-img {
        @include position($position: absolute);
      }
    }
  }

  &.parents-friends {
    min-height: 500px;
    .section-title {
      @media screen and (min-width: $viewPort-tablet) {
        padding-bottom: 9vw;
      }
      h1 {
        color: #ffab00;
      }

      p span {
        margin-bottom: 36px;
      }

      .title-note {
        max-width: 410px;
        @media screen and (min-width: $viewPort-desktop) {
          max-width: 100%;
        }
        b {
          margin: -20px 0 0;
          color: #ffab00;

          @media screen and (min-width: $viewPort-tablet) {
            margin: -2px 10px 0 0;
          }
        }

        span {
          color: #e19c0f;
        }
      }
    }

    .image__wrapper {
      padding: 30px 22px 0 0;
      margin: 0 -15px;

      @media screen and (min-width: $viewPort-tablet) {
        padding-left: 63px;
        margin: 0 -50px;
      }

      @media screen and (min-width: $viewPort-desktop) {
        padding: 0;
        margin: 0 0 50px;
      }

      .main-img {
        max-width: 683px;

        @media screen and (min-width: $viewPort-desktop) {
          max-width: 735px;
          @include position($position: absolute, $bottom: -1vw);
        }
      }
    }
  }

  &.parents-educators {
    .section-title {
      padding-bottom: 27px;

      @media screen and (min-width: $viewPort-desktop) {
        padding-bottom: 9vw;
      }

      h1 {
        color: #f26221;
      }

      p {
        margin-bottom: 37px;
      }

      .title-note {
        b {
          margin: -20px 0 0;
          color: #f26221;

          @media screen and (min-width: $viewPort-tablet) {
            margin: -2px 10px 0 0;
          }
        }

        span {
          color: #ce5119;
        }
      }
    }

    .image__wrapper {
      width: 100%;
      @media screen and (min-width: $viewPort-desktop) {
        width: 50%;
      }
      .main-img {
        max-width: 718px;

        @media screen and (min-width: $viewPort-desktop) {
          @include position($position: absolute, $bottom: 40px, $left: -2vw);
        }
      }

      .child-img {
        width: 19vw;
        bottom: 30px;
        left: 60vw;
        z-index: 2;

        @media screen and (min-width: $viewPort-tablet) {
          left: 460px;
          max-width: 153px;
        }

        @media screen and (min-width: $viewPort-desktop) {
          width: 10vw;
          max-width: 147px;
          right: initial;
          left: 22vw;
          bottom: 56px;
        }

        @media screen and (min-width: 1440px) {
          left: 315px;
        }
      }
    }
  }

  &.potential-employers {
    padding: 0 0 54px;

    @media screen and (min-width: $viewPort-tablet) {
      padding: 0 0 73px;
    }
    .section-title {
      padding-bottom: 39px;

      @media screen and (min-width: $viewPort-tablet) {
        padding-bottom: 62px;
      }

      @media screen and (min-width: $viewPort-desktop) {
        padding-bottom: 45px;
      }

      h1 {
        color: #8ba63b;
      }

      & > span {
        margin-bottom: 36px;
      }

      p {
        b {
          margin: -20px 0 0;
          color: #8ba63b;

          @media screen and (min-width: $viewPort-tablet) {
            margin: -2px 5px 0 0;
          }
        }
      }

      .title-note {
        color: #7f9931;
        b {
          color: #8ba63b;
        }
      }
    }

    .image__wrapper {
      padding-left: 15px;
      margin-right: -15px;

      @media screen and (min-width: $viewPort-tablet) {
        padding-left: 50px;
        margin-right: -50px;
      }

      @media screen and (min-width: $viewPort-desktop) {
        position: relative;
        padding-left: 0;
      }

      & > img {
        @media screen and (min-width: $viewPort-desktop) {
          @include position($position: absolute, $right: -10px, $bottom: 50px);
          width: 42vw;
          max-width: 604px;
        }
      }
    }
  }

  &.introduction {
    .section-title {
      h1 {
        color: $red-500;
      }
    }

    .title-note {
      display: flex;
      align-items: center;
      color: #b70b51;

      b {
        color: $red-500;
      }
    }

    .image__wrapper {
      padding-left: 15px;
      margin-right: -15px;

      @media screen and (min-width: $viewPort-tablet) {
        max-width: 756px;
        padding-left: 50px;
        margin-right: -50px;
      }

      @media screen and (min-width: $viewPort-desktop) {
        max-width: 960px;
        padding: 95px 0 0 54px;
        margin: 0;
      }

      .main-img {
        position: relative;
        width: 524px;

        @media screen and (min-width: $viewPort-tablet) {
          width: 756px;
        }

        @media screen and (min-width: $viewPort-desktop) {
          width: 1086px;
        }
      }

      .child-img {
        width: 51px;
        bottom: 0;
        left: 115px;
        z-index: 3;

        @media screen and (min-width: $viewPort-tablet) {
          width: 73px;
          left: 195px;
          bottom: 2vw;
        }

        @media screen and (min-width: $viewPort-desktop) {
          bottom: 5%;
          width: 106px;
          left: 260px;
        }
      }
    }
  }

  &.jobs {
    .section-title {
      h1,
      a {
        color: $red-500;
      }
    }
  }

  .decoration-square {
    @include position($position: absolute, $top: -40px, $left: 20px);
    width: 80px;
    height: 80px;
    z-index: 3;
    opacity: 0.6;
    @media screen and (min-width: $viewPort-tablet) {
      width: 120px;
      height: 120px;
      top: -56px;
      left: 50px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      top: 47%;
      transform: translate(-100px, -50%);
      z-index: 2;
    }
  }

  &.find {
    .section-title__wrapper {
      min-height: unset;
    }

    .section-title {
      h1 {
        color: #8ba63b;
      }

      a {
        color: $red-500;
      }
    }
  }
}
