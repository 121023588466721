@import "../../assets/scss/shared";

.nav-sidebar {
  h2 {
    font-weight: 300;
    font-size: 26px;
    @media screen and (min-width: $viewPort-desktop) {
      display: none;
    }
  }

  &__list {
    li {
      &.active-item {
        button,
        a {
          background-color: $hospFood;
          cursor: default;
          svg {
            display: none;
          }
        }
      }

      .text {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 15px;
      }

      &:not(.active-item) {
        button,
        a {
          background-color: $green-800 !important;
        }
      }

      &:not(:last-of-type) {
        margin-bottom: 25px;
      }

      button,
      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 13px 47px 13px 21px;
        font-size: 16px;
        line-height: 1.7;
        text-decoration: none;
        color: $white;
        border: none;
        width: 100%;
        transition: background 0.3s ease;
        outline: none;
        border-radius: none;
        cursor: pointer;

        @media screen and (min-width: $viewPort-desktop) {
          padding-right: 23px;
        }

        &:hover {
          svg {
            left: 3px;
          }
        }
      }

      svg {
        width: 12px;
        height: auto;
        transition: left 0.3s ease;
        position: relative;
        left: 0;
      }
    }
  }
}
