@import "../../assets/scss/_shared.scss";

.ButtonIcon {
  color: $grey-3;
  display: inline-block;

  &__container {
    position: relative;

    & > button {
      cursor: pointer;
    }
  }

  &__icon {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(0, -100%);
    width: 60px;
    height: 60px;
    background-color: $white;
    font-size: 16px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

    border: 0;
  }

  &__button {
    display: flex;
    align-items: center;
    min-width: 180px;
    height: 60px;
    text-align: center;
    padding: 0 20px;
    position: relative;
    z-index: 2;
    opacity: 1 !important;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border: none;

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    &::before,
    &::after {
      content: "";
      @include position($position: absolute, $top: 0, $left: 0);
      height: 100%;
      background-color: $green-200;
      width: 0;
      transition: width 0.3s ease;
      z-index: -1;
    }
    &::before {
      background-color: $green-300;
      z-index: -2;
      width: 100%;
      opacity: 1;
    }
  }

  &:hover {
    text-decoration: none;
    color: $grey-3;
    .ButtonIcon__button {
      &::after {
        width: 100%;
      }
    }
  }
}
