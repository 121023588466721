@import "../../assets/scss/shared";

.company-logos {
  position: relative;
  margin: 0 0 67px;
  @media screen and (min-width: $viewPort-tablet) {
    margin-bottom: 57px;
  }
  @media screen and (min-width: $viewPort-desktop) {
    margin-bottom: 80px;
  }

  &__holder {
    margin: 0;
    padding: 0;
    list-style: none;
    @media screen and (min-width: $viewPort-tablet) {
      margin-left: -10px;
      margin-right: -10px;
      display: flex;
      flex-wrap: wrap;
    }

    li {
      position: relative;
      padding-top: 62%;
      margin: 0 0 30px;
      min-height: 194px;
      @media screen and (min-width: $viewPort-tablet) {
        flex: calc(50% - 20px) 0 0;
        max-width: calc(50% - 20px);
        padding-top: 29%;
        margin: 0 10px 20px;
      }
      @media screen and (min-width: $viewPort-desktop) {
        padding-top: 24%;
        flex: calc(33.3333% - 20px) 0 0;
        max-width: calc(33.3333% - 20px);
        min-height: 220px;
      }

      img {
        @include position($position: absolute, $top: 0, $left: 0);
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &.btn-item {
        background-color: $green-600;
        min-height: 240px;
        margin-bottom: 0;
        @media screen and (min-width: $viewPort-tablet) {
          padding-top: 35%;
          flex: 100% 0 0;
          max-width: 100%;
          margin-bottom: 20px;
        }
        @media screen and (min-width: $viewPort-desktop) {
          min-height: 220px;
          padding-top: 24%;
          flex: calc(33.3333% - 20px) 0 0;
          max-width: calc(33.3333% - 20px);
        }

        .decoration-letter {
          @include position($position: absolute, $top: 20px, $left: 26px);
          max-width: 59px;

          @media screen and (min-width: $viewPort-desktop) {
            max-width: 54px;
            top: 19px;
            left: 24px;
          }
        }
      }
    }
  }

  .link-double {
    @include position($position: absolute, $bottom: 0, $right: 0);
    z-index: 2;
  }
}
