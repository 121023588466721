@import "../../../assets/scss/shared";
$article-margin: 2px;
$article-padding: 24px;
.ArticleBlurb {
  margin: $article-margin;
  display: flex;
  flex-direction: column;
  padding-right: $article-padding;
  width: 464px;
  min-height: $card-height;
  h1 {
    margin: 0px;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: 0.01em;
    text-align: left;
  }

  &__blurb {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 170%;
  }
  &__button {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
