@import "../shared";

.educators {
  .page-section {
    &.myth_busters__holder {
      margin-top: 0;
      @media screen and (min-width: $viewPort-tablet) {
        margin-top: 0;
      }
      @media screen and (min-width: $viewPort-desktop) {
        margin-top: 0;
      }
    }
  }
}

.page-section {
  &.page_head__holder {
    margin-bottom: 0;
    padding: 0;
    z-index: 0;

    .skew-bottom-background {
      bottom: 0;
    }
  }
}
