@import "../../assets/scss/shared";

.jobs {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 170%;

  .apprenticeship_jobs__holder .container {
    padding: 0;
    max-width: unset;
  }

  &__header {
    padding-bottom: 33px;
    background-color: transparent;
    margin-top: -400px;

    @media screen and (max-width: $viewPort-desktop) {
      margin-top: -430px;
    }

    @media screen and (max-width: $viewPort-tablet) {
      margin-top: -450px;
    }

    &-skew {
      position: relative;
      height: 500px;

      background-color: $green-600;
      z-index: -1;
      transform: skewY(-6.6deg);

      @media screen and (min-width: $viewPort-desktop) {
        transform: skewY(-5.4deg);
      }
    }
  }

  &__content {
    width: 100%;

    &-unskew {
      height: 500px;
      background-color: white;
      margin-bottom: -500px;
    }

    .jobs__container-wrapper {
      padding-top: 58px;
    }
  }

  &__input-group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 40px;

    @media screen and (max-width: $viewPort-tablet) {
      grid-template-columns: 1fr;
      row-gap: 20px;
    }
  }

  &__listbox,
  &__input {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 73px;

    label {
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: $green-300;
    }

    button,
    &-container,
    input {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 170%;

      height: 100%;
      text-align: left;
      background-color: transparent;
      border: 0;
      color: $grey-300;
    }

    button,
    &-container {
      padding: 15px 0;
      border-bottom: 1px solid $grey-500;
    }

    button {
      cursor: pointer;
    }
  }

  &__listbox {
    ul {
      position: absolute;
      top: 73px;
      width: 100%;
      // min-width: 100%;
      background-color: white;
      box-shadow: 0px 2px 16px rgba(45, 46, 72, 0.15);
      border-radius: 2px;
      padding: 18px 24px;

      max-height: 60vh;
      overflow: auto;
      z-index: 2;
    }

    li {
      margin: 18px 0 18px 34px;
      color: $grey-1000;
      cursor: pointer;
      line-height: 130%;

      &.selected {
        margin-left: 0px;
        color: $grey-1000;

        .fa-check {
          color: $green-400;
          width: 24px;
          margin-right: 10px;
        }
      }
      &.active {
        color: $green-400;
      }
    }
  }

  &__input-icon {
    position: absolute;
    right: 0;
    cursor: pointer;
  }

  &__input {
    input {
      width: calc(100% - 11px - 8px);
    }

    &__input-icon {
      padding-left: 8px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 6.5px;
    padding-bottom: 6.5px;
    z-index: 1;

    button {
      font-family: Poppins;
      font-style: normal;
      font-size: 14px;
      line-height: 21px;
      cursor: pointer;
    }

    .search-button {
      position: relative;
      width: 153px;
      height: 60px;
      font-weight: 600;
      box-sizing: border-box;
      background-color: transparent;
      color: white;
      border: 3px solid $green-100;
      text-align: left;
      padding-left: 18px;
      transition: border 0.3s ease, background 0.3s ease, color 0.3s ease;

      .text {
        position: relative;
        z-index: 1;
      }

      &::after {
        content: "⟶";
        color: $green-300;
        margin-left: 44px;
        font-size: 18px;
        transition: color 0.3s ease;
        position: relative;
        z-index: 1;
      }

      &::before {
        // animation on hover
        content: "";
        @include position($position: absolute, $top: 0, $left: 0);
        height: 100%;
        background-color: $green-100;
        width: 0;
        opacity: 0;
        transition: width 0.3s ease, opacity 0.3s ease;
      }

      &:hover {
        color: $green-1000;
        text-decoration: none;
        &::before {
          width: 100%;
          opacity: 1;
        }
      }
    }

    .reset-button {
      height: 55px;
      width: calc(100% - 153px);
      max-width: 127px;
      font-weight: 500;
      background-color: transparent;
      color: $green-300;
      border: 0;
    }
  }

  &__sort {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 0;

    &--pad-bot {
      margin-bottom: 5.5px;
    }
  }

  &__container-wrapper {
    @include container();

    padding: 0 15px;

    @media screen and (min-width: $viewPort-tablet) {
      padding: 0 50px;
    }

    @media screen and (min-width: $viewPort-desktop) {
      padding: 0 110px;
    }
  }

  &__table {
    padding-top: 58px;
    border-collapse: collapse;
    width: 100%;

    P {
      margin: 0;
    }

    a {
      text-decoration: none;
      color: $grey-3;
      &:hover {
        text-decoration: none;
      }
    }

    tbody,
    thead {
      width: 100%;
      display: block;
    }

    tbody tr,
    thead tr {
      text-align: left;
      background: #fff;
      display: grid;
      grid-template-columns: 0.5fr 1.5fr 1fr 1.5fr 1fr 0.75fr;
      column-gap: 20px;
      width: 100%;
      padding: 20px;
    }

    tbody tr:hover {
      background-color: $green-200;
      cursor: pointer;
    }

    thead th {
      display: flex;

      &.sortable {
        cursor: pointer;
      }
    }

    thead {
      text-transform: uppercase;
      color: $green-500;
      font-weight: bold;
      font-size: 12px;
      line-height: 125%;
      letter-spacing: 0.2em;
    }

    tr {
      border-bottom: thin solid $grey-500;
    }
  }

  &__empty {
    height: 576px;
    margin-bottom: 109px;
    position: relative;

    .decoration-letter {
      pointer-events: none;
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      opacity: 0.05;
    }

    div {
      justify-content: center;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      font-size: 16px;
      line-height: 170%;
      color: $grey-800;
    }

    button {
      padding: 17px 20px;
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: $green-300;
      border: none;
      background-color: transparent;
    }
  }

  &__tablet-header {
    text-transform: uppercase;
    color: $green-500;
    font-weight: bold;
    font-size: 12px;
    line-height: 125%;
    letter-spacing: 0.2em;

    text-align: left;
    background: #fff;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2.5fr;
    column-gap: 20px;
    width: 100%;
    padding: 20px;

    div {
      display: flex;

      &.sortable {
        cursor: pointer;
        margin-right: 50px;
      }
    }
  }

  &__tablet-head-spacer {
    display: grid;
    grid-template-columns: 7fr 0.5fr;
    border-bottom: thin solid $grey-500;
  }

  &__tablet-container {
    display: grid;
    grid-template-columns: 7fr 0.5fr;
    border-bottom: thin solid $grey-500;
  }

  &__tablet-columns {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2.5fr;
    column-gap: 20px;
  }

  &__tablet-row {
    text-align: left;
    background: #fff;
    column-gap: 20px;
    width: 100%;
    padding: 20px;
  }

  &__tablet-hidden-row {
    display: flex;
    margin-top: 20px;
  }

  &__tablet-row-chev {
    margin-top: 26px;
    color: $green-500;
    display: flex;
    align-items: center;
  }

  &__tablet-column {
    margin-right: 80px;
  }

  &__tablet-column-title {
    text-transform: uppercase;
    color: $green-500;
    font-weight: bold;
    font-size: 12px;
    line-height: 125%;
    letter-spacing: 0.2em;
    margin: 4px 0;
  }

  &__tablet-details-button {
    margin: 20px auto 0;
    text-align: center;
  }

  &__mobile-header {
    text-transform: uppercase;
    color: $green-500;
    font-weight: bold;
    font-size: 12px;
    line-height: 125%;
    letter-spacing: 0.2em;

    text-align: left;
    background: #fff;
    display: grid;
    grid-template-columns: 1fr 3fr;
    width: 100%;
    padding: 20px;

    div {
      display: flex;

      &.sortable {
        cursor: pointer;
        margin-right: 50px;
      }
    }
  }

  &__mobile-head-spacer {
    display: grid;
    grid-template-columns: 7fr 1fr;
    border-bottom: thin solid $grey-500;
  }

  &__mobile-container {
    display: grid;
    grid-template-columns: 7fr 1fr;
    border-bottom: thin solid $grey-500;
  }

  &__mobile-columns {
    display: grid;
    grid-template-columns: 1fr 3fr;
  }

  &__mobile-row {
    text-align: left;
    background: #fff;
    column-gap: 20px;
    width: 100%;
    padding: 20px;
  }

  &__mobile-hidden-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
    column-gap: 20px;
    margin-top: 20px;
  }

  &__mobile-row-chev {
    margin-top: 26px;
    color: $green-500;
    display: flex;
    align-items: center;
  }

  &__mobile-column-title {
    text-transform: uppercase;
    color: $green-500;
    font-weight: bold;
    font-size: 12px;
    line-height: 125%;
    letter-spacing: 0.2em;
    margin: 4px 0;
  }

  &__container {
    @include container();
    padding: 50px 0;

    @media screen and (min-width: $viewPort-desktop) {
      padding: 80px 0;
    }

    h3 {
      margin: 0 0 40px;
      max-width: 750px;
    }

    p {
      margin: 0 0 30px;
      max-width: 650px;
    }

    img {
      width: auto;
      max-width: 100%;

      @media screen and (min-width: $viewPort-desktop) {
        max-width: none;
      }
    }
  }
  .page_head__holder + .page_head__holder {
    // Fix for double header on career-seekers: jobs
    margin-top: 75px;
    margin-bottom: -25px;
  }
  .page_head__holder + .simple_content__holder {
    // todo one possible fix -> change padding to margin
    // padding: 0 0;
    // margin: 80px 0;
  }
}
