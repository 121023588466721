@import "../../assets/scss/shared";

.about {
  font-weight: 300;
  font-size: 20px;
  line-height: 1.5;
  color: $white;

  @media screen and (min-width: $viewPort-tablet) {
    font-size: 28px;
    line-height: 1.3;
  }

  @media screen and (min-width: $viewPort-desktop) {
    font-size: 22px;
    line-height: 1.5;
    letter-spacing: -0.015em;
  }

  h2 {
    font-size: 20px;
    line-height: 1.5;
    color: $red-500;

    @media screen and (min-width: $viewPort-tablet) {
      font-size: 26px;
      margin-bottom: 50px;
    }

    @media screen and (min-width: $viewPort-desktop) {
      font-size: 30px;
      margin-bottom: 36px;
    }
  }

  &__content {
    max-width: 614px;
  }

  p {
    margin: 0;
  }
}
