@import "../../assets/scss/shared";

.sorted-sliders {
  position: relative;
  margin-right: -9999px;
  padding-right: 9999px;

  @media screen and (min-width: $viewPort-desktop) {
    overflow: hidden;
  }

  .slick-slide {
    padding-right: 12px;

    @media screen and (min-width: $viewPort-tablet) {
      padding-right: 25px;
    }

    @media screen and (min-width: $viewPort-desktop) {
      padding-right: 30px;
    }
  }

  .slick-slider {
    position: static;
    margin: 0 7px 0 16px;
    padding-bottom: 20px;
    max-width: 360px;

    @media screen and (min-width: $viewPort-tablet) {
      max-width: 674px;
      padding-bottom: 31px;
    }

    @media screen and (min-width: $viewPort-desktop) {
      max-width: none;
    }

    @media screen and (min-width: $viewPort-full-screen) {
      margin: 0;
      width: 1080px;
      padding-bottom: 72px;
    }

    &__wrapper {
      position: relative;
    }

    .slick-list {
      overflow: visible;
    }
  }

  .slick-arrow {
    @include position(
      $position: absolute,
      $top: -81px,
      $left: auto,
      $right: 170px
    );
    width: auto;
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    background: none;
    padding: 0 4px;

    @media screen and (min-width: $viewPort-desktop) {
      right: 213px;
      top: -88px;
    }

    &::before {
      font-size: 14px;
      transition: left 0.3s ease, color 0.3s ease;
      position: relative;
      left: 0;
      color: $green-300;
    }

    &:not(.slick-disabled):hover {
      &::before {
        color: $green-300;
      }
    }

    &.slick-next {
      &::before {
        content: "⟶";
      }
      &:not(.slick-disabled):hover {
        &::before {
          left: 3px;
        }
      }
    }

    &.slick-prev {
      margin-right: 61px;
      &::before {
        content: "⟵";
      }
      &:not(.slick-disabled):hover {
        &::before {
          left: -3px;
        }
      }
    }
  }

  .slick-dots {
    position: static;
    bottom: auto;
    right: 56px;
    display: flex !important;
    @include position($position: absolute, $bottom: -2px, $left: 26px);
    width: auto;
    max-width: 1000px;

    @media screen and (min-width: $viewPort-tablet) {
      left: 0;
      right: 79px;
    }

    @media screen and (min-width: $viewPort-desktop) {
      left: 26px;
    }

    &::before {
      content: "";
      @include position($position: absolute, $top: 50%, $left: 0);
      width: 100%;
      margin-top: -1px;
      height: 2px;
      background-color: $grey-300;
      opacity: 0.25;
    }

    li {
      width: 100%;
      margin: 0;
      display: flex;
      height: auto;

      &.slick-active {
        button {
          &::before {
            opacity: 0;
          }
          &::after {
            width: 100%;
          }
        }

        & ~ li {
          button {
            &::after,
            &::before {
              opacity: 0;
            }
          }
        }
      }

      button {
        width: 100%;
        height: 20px;
        padding: 0;
        cursor: pointer;
        overflow: hidden;
        position: relative;

        &::after,
        &::before {
          content: "";
          @include position($position: absolute, $top: 50%, $left: 0);
          width: 100%;
          margin-top: -1px;
          height: 2px;
          background-color: $green-200;
          opacity: 1;
        }

        &::after {
          width: 0;
          transition: width 0.2s ease;
        }
      }
    }
  }
}

.slick-slider__wrapper {
  &__counter {
    max-width: 1056px;
    margin-right: 25px;
    overflow: hidden;

    @media screen and (min-width: $viewPort-tablet) {
      margin-right: 54px;
      margin-left: -26px;
    }

    @media screen and (min-width: $viewPort-desktop) {
      margin-left: 0;
    }

    .current,
    .total {
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.2em;
      color: $green-200;
      float: left;
    }

    .total {
      color: $white;
      float: right;
    }
  }
}

.has-main-background {
  .sorted-sliders .slick-arrow {
    &::before {
      color: $white;
    }
    &:not(.slick-disabled):hover {
      &::before {
        color: $green-300;
      }
    }
  }
}
