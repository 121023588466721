@import "./fonts";
@import "./shared";
@import "./layouts/mainLayouts";
@import "./typography";

/* ------------------------------------------------------------ *\
   Classes for the background-colors
\* ------------------------------------------------------------ */
.bg-green-600 {
  background-color: $green-600;
}

/* ------------------------------------------------------------ *\
 General Styles
\* ------------------------------------------------------------ */
* {
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
  min-width: 320px;
  margin: 0;
  font-family: Poppins, Tahoma, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  color: $black;
  -webkit-overflow-scrolling: none;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

.generation-apprenticeship-app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

input {
  font-family: Poppins, Tahoma, sans-serif;
  font-weight: 400;
  text-overflow: ellipsis;
  outline: none;
}

img {
  width: 100%;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
}

b {
  font-weight: 700;
}

/* ------------------------------------------------------------ *\
    Hides elements leaving accessible for the screen-readers
\* ------------------------------------------------------------ */
.visually-hidden {
  @include position($position: absolute, $left: -9999px);
  opacity: 0;
  visibility: hidden;
}

/* ------------------------------------------------------------ *\
   Animation for the social network icons
\* ------------------------------------------------------------ */
@keyframes bounce {
  0%,
  20%,
  60%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  80% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

.modal-open {
  overflow: hidden;

  .page-section {
    position: static;
  }

  .slick-slider {
    .slick-track,
    .slick-list {
      transform: none !important;
    }
  }
}

.slick-slider {
  .slick-track {
    margin-left: 0;
  }
}

.mh-200 {
  min-height: 200px;
}

.pt-60 {
  padding-top: 60px;
}

.pb-100 {
  padding-bottom: 60px;
}

blockquote {
  border-left: 4px solid $green-200;
  margin: 0;
  padding: 8px 26px;

  p {
    &:last-child {
      margin: 0 !important;
    }
  }
}
