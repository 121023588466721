@import "../../assets/scss/shared";

$newsCardWidth: 300px;
.NewsGallery {
  &__title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: $grey-1000;
    margin: 25px 10px 50px 10px;
  }
  &__gallery {
    display: grid;
    grid-template-columns: repeat(1, $newsCardWidth);
    grid-gap: 48px;
    padding: 10px 0px;
    @media screen and (min-width: $viewPort-tablet) {
      grid-template-columns: repeat(2, $newsCardWidth);
    }
    @media screen and (min-width: $viewPort-desktop) {
      grid-template-columns: repeat(3, $newsCardWidth);
    }
  }
  &__card-wrapper {
    margin: 25px auto;
  }
}
