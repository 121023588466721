@import "../../assets/scss/shared";

.hero-main {
  color: $white;
  margin: 0 0 -96px;

  @media screen and (min-width: $viewPort-tablet) {
    margin: 0 0 -77px;
  }

  @media screen and (min-width: $viewPort-desktop) {
    margin: 0 0 -174px;
  }

  .description__wrapper {
    position: relative;
    z-index: 2;

    @media screen and (min-width: $viewPort-desktop) {
      margin-top: 50px;
    }
  }

  h1 {
    color: $white;
    max-width: 400px;
    font-size: 28px;
    line-height: 1.25;
    margin: 0 0 9px;
    @media screen and (min-width: $viewPort-tablet) {
      max-width: 700px;
      font-size: 42px;
      margin: 0 0 24px;
      max-width: 57%;
    }
  }

  h2 {
    font-weight: 300;
    margin: 0 0 32px;
    color: $white;
    max-width: 500px;
    font-size: 18px;
    line-height: 1.25;
    @media screen and (min-width: $viewPort-tablet) {
      max-width: 860px;
    }

    @media screen and (min-width: $viewPort-tablet) {
      font-size: 28px;
      line-height: 1.3;
      margin: 0 0 32px;
    }

    @media screen and (min-width: $viewPort-desktop) {
      margin: 0 0 102px;
      line-height: 1.25;
      max-width: 70%;
    }
  }
}

.hero_main__holder {
  &::before {
    content: "";
    @include skewLine(transparent);
    background-color: $green-600;
    height: auto;
    top: -9999px;
    bottom: 0;
  }
}

.list-links {
  margin: 0 0 -11px;
  padding: 0;
  list-style: none;
  display: flex;

  li {
    margin: 10px 10px 10px 10px;
  }

  @media screen and (min-width: $viewPort-desktop) {
    li:first-child {
      margin-left: 0px;
    }
  }

  @media screen and (max-width: $viewPort-tablet) {
    flex-wrap: wrap;
  }
}
