@import "../../assets/scss/shared";

.main-header {
  @include bg-color($green-600);

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: $white;
  z-index: 5;

  &__nav {
    @include container();

    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 12px 0 9px 25px;

    @media screen and (min-width: $viewPort-tablet) {
      padding: 24px 0 23px 46px;
    }

    @media screen and (min-width: $viewPort-desktop) {
      padding: 22px 0 25px 21px;
    }

    .burger-btn {
      @include btn();

      position: relative;
      padding: 0;
      width: 44px;
      height: 44px;
      background-color: transparent;
      transition: background 0.3s;

      &__icon {
        @include position($position: absolute, $top: 50%, $left: 50%);
        right: 18px;
        width: 20px;
        height: 1.5px;
        background-color: $white;
        transform: translate(-50%, -50%);
        transition: background 0s 0.3s;

        &::before,
        &::after {
          content: "";
          @include position($position: absolute, $left: 0);
          width: 100%;
          height: 1.5px;
          background-color: $white;
          transition-duration: 0.3s, 0.3s;
          transition-delay: 0.3s, 0s;
        }

        &::before {
          top: -5px;
          transition-property: top, transform;
        }

        &::after {
          bottom: -5px;
          transition-property: bottom, transform;
        }
      }

      &.burger-open {
        .burger-btn__icon {
          background: none;

          &::before {
            top: 0;
            transform: rotate(45deg);
          }

          &::after {
            bottom: 0;
            transform: rotate(-45deg);
          }

          &::before,
          &::after {
            transition-delay: 0s, 0.3s;
          }
        }
      }
    }

    &__logo {
      display: inline-block;
      width: 144px;
      height: 39px;
      margin-left: 21px;
      background: url(../../assets/img/logo.svg) 0 0/100% 100% no-repeat;

      @media screen and (min-width: $viewPort-tablet) {
        width: 196px;
        height: 53px;
        margin-left: 36px;
      }

      @media screen and (min-width: 1250px) {
        margin: 0 130px 0 0;
      }
    }

    .main-sub-menu__wrapper {
      @include bg-color($green-600);
    }
  }
}
