@import "../../assets/scss/shared";

.instructors {
  .about__wrapper {
    @include bg-color($green-600);
  }

  .faq__wrapper {
    @media screen and (min-width: 768px) {
      background: linear-gradient(-6deg, $green-600 12%, $white 6.2%);
    }

    @media screen and (min-width: 1440px) {
      background: linear-gradient(
                      -6deg,
                      $green-600 14%,
                      $white 9.2%,
                      $white 95%,
                      $green-600 95.2%
      );
    }

    .decor-line {
      @include decor-sloping-line($red-500);
    }
  }

  .success-stories__wrapper {
    @media screen and (min-width: $viewPort-tablet) {
      @include bg-color($green-600);

      h2 {
        color: $white;
      }
    }

    @media screen and (min-width: 1440px) {
      background: linear-gradient(-6deg, $green-600 95%, $white 95.2%);
    }
  }

  .yt-video {
    width: 100%;
  }
}
