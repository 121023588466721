@import "../../assets/scss/shared";

.PhotoGallery {
  margin: 20px 20px 50px 0px;
  padding: 20px 20px 20px 0px;
  &__title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: $grey-1000;
    margin: 25px 10px 50px 10px;
  }
  &__gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, $photo-width);
    grid-gap: 1.2rem;
    padding: 10px 0px;
  }
}
