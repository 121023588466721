@import "../../assets/scss/shared";

.search-tool {
  padding: 0 15px;
  margin-bottom: 64px;
  position: relative;
  z-index: 1;
  @media screen and (min-width: $viewPort-tablet) {
    padding: 0;
    margin-bottom: 75px;
  }
  @media screen and (min-width: $viewPort-desktop) {
    max-width: 739px;
    margin-bottom: 35px;
  }

  &.is-error {
    .search-input {
      input {
        border-bottom-color: $red-error;
      }
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    text-align: center;
    width: 30px;
    padding-top: 1px;
    border: none;
    cursor: pointer;

    svg {
      display: block;
      margin: 0 auto;
      font-size: 20px;
      color: $white;
    }
  }

  &__holder {
    @media screen and (min-width: $viewPort-tablet) {
      display: flex;
      align-items: flex-end;
    }
  }

  .search-input {
    position: relative;
    display: block;
    margin-bottom: 10px;

    @media screen and (min-width: $viewPort-tablet) {
      width: 100%;
      margin-bottom: 0;
    }

    .fa-search {
      @include position($position: absolute, $top: 3px, $left: 0);
      font-size: 18px;
      color: $grey-300;
    }

    input {
      width: 100%;
      padding: 0 35px 17px 25px;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      border: 0;
      border-bottom: 1px solid rgba(191, 194, 196, 0.25);
      color: $white;
      background-color: transparent;

      &::placeholder {
        font-size: 18px;
        color: $grey-300;
      }
    }
  }

  .search-btn {
    @include btn();

    display: flex;
    align-items: center;
    padding: 0 0 0 20px;
    margin-left: auto;
    font-size: 14px;
    line-height: 1.5;
    color: $green-300;
    background-color: transparent;
    transition: color 0.5s, background-color 0.5s;
    position: relative;

    @media screen and (min-width: $viewPort-tablet) {
      margin-left: 8px;
      float: right;
    }

    &::before {
      content: "";
      @include position($position: absolute, $top: 0, $right: 0);
      height: 100%;
      background-color: $green-300;
      z-index: -1;
      width: 0;
      opacity: 0;
      transition: width 0.3s ease, opacity 0.3s ease;
    }

    &:hover {
      color: $green-1000;

      &::before {
        width: 100%;
        opacity: 1;
      }

      & > span {
        color: $green-1000;
      }
    }

    &.is-searching {
      span {
        background-color: $green-400;
        color: $green-400;
        position: relative;
      }
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 59px;
      height: 60px;
      margin-left: 26px;
      color: $white;
      background-color: $green-300;
      transition: color 0.5s;

      .loader {
        position: absolute;
        top: 46%;
        left: 46%;
      }
    }
  }
}
