.btn-expand-all {
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  border: 0;
  padding: 5px;
  background: none;
  outline: none;
  cursor: pointer;
  color: #47878e;
}
