@import "../../assets/scss/shared";

.aside-nav,
.aside-sub-nav {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100vh - 60px);
  margin-top: 60px;
  border-top: 2px solid $green-500;
  transition: 0.5s;
  color: $white;
  overflow-y: scroll;

  @media screen and (min-width: $viewPort-tablet) {
    height: calc(100vh - 100px);
    margin-top: 100px;
  }
}

.aside-nav {
  @include bg-color($green-600);

  transform: translateX(-100%);
  padding: 55px 26px 65px;
  z-index: 100;

  font-size: 22px;

  @media screen and (min-width: $viewPort-tablet) {
    padding: 74px 30px 77px 44px;
  }

  &.aside-open {
    transform: translateX(0);
  }

  .active {
    font-weight: bold;
  }

  &__menu {
    overflow-y: scroll;

    @media screen and (min-width: $viewPort-tablet) {
      font-size: 30px;
    }

    & > li {
      margin-bottom: 45px;

      &.submenu-item > p {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;

        span {
          font-size: 24px;

          @media screen and (min-width: $viewPort-tablet) {
            font-size: 32px;
          }
        }
      }

      & > a {
        color: $white;
        text-decoration: none;
      }
    }
  }

  &__submenu {
    @include bg-color($green-600);

    position: fixed;
    top: 2px;
    left: 0;
    width: 100%;
    margin-top: 60px;
    transform: translateX(-100%);
    transition: 0.5s;
    color: $white;
    overflow: hidden;
    z-index: 101;

    &.submenu-open {
      transform: translateX(0);
    }
  }
}

.aside-sub-nav {
  @include bg-color($green-700);

  transform: translateX(100%);
  padding: 30px 25px 65px 26px;
  z-index: 101;

  @media screen and (min-width: $viewPort-tablet) {
    padding: 60px 44px;
  }

  &.aside-sub-nav-open {
    transform: translateX(0);
  }

  &__submenu {
    .back-to-main-menu {
      margin-bottom: 45px;

      button {
        @include btn();
        @include bg-color($green-300);

        width: 60px;
        height: 59px;
        margin-right: 26px;
        border: 2px solid $green-300;
        color: $white;
        transition: 0.5s;

        &:hover,
        &:focus,
        &:active {
          background-color: $white;
          color: $green-300;
        }
      }

      span {
        font-size: 14px;
        line-height: 1.5;
        color: $green-300;
      }
    }

    .active {
      font-weight: bold;
    }

    & > strong {
      position: relative;
      display: block;
      margin-bottom: 5px;
      padding-bottom: 15px;
      font-weight: 300;
      font-size: 22px;
      line-height: 1.5;

      @media screen and (min-width: $viewPort-tablet) {
        padding-bottom: 20px;
        margin-bottom: 0;
        font-size: 30px;
        line-height: 1.4;
      }

      &::after {
        @include bg-color($grey-300);

        content: "";
        @include position($position: absolute, $left: 0, $bottom: 0);
        width: 100%;
        height: 1px;
      }
    }

    &-list {
      margin-bottom: 30px;

      a {
        color: $white;
      }

      & > li {
        margin-top: 30px;
        font-weight: 300;
        font-size: 18px;
        line-height: 1.5;

        @media screen and (min-width: $viewPort-tablet) {
          font-size: 20px;
        }
      }
    }
  }
}
