@import "../../assets/scss/shared";

.providers__holder .container {
  @media screen and (max-width: $viewPort-desktop) {
    padding: 0;
    max-width: unset;
  }
}

.LocalProvidersMaps {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 17px 30px;

    @media screen and (min-width: $viewPort-tablet) {
      margin: 68px 47px 30px;
    }

    @media screen and (min-width: $viewPort-desktop) {
      flex-direction: row;
      margin: 65px 0;
    }

    &-title,
    &-filter {
      width: 100%;
    }

    h4 {
      display: inline-block;
      margin: 0;

      @media screen and (min-width: $viewPort-desktop) {
        margin-left: 15px;
      }
    }

    &-filter {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 55px;

      @media screen and (min-width: $viewPort-desktop) {
        margin-top: 0;
      }

      &-space,
      &-reset {
        width: 130px;
      }

      &-space {
        display: none;

        @media screen and (min-width: $viewPort-desktop) {
          display: unset;
        }
      }

      &-input {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $green-500;

        & > * {
          height: 40px;
          margin-top: 14px;
          padding-bottom: 14px;
        }

        & svg:first-child {
          position: absolute;
          top: 0;
          left: 0;
        }

        & svg:last-child {
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
        }

        input {
          width: 100%;
          padding: 0 25px 14px;
          color: $green-500;
          font-family: Poppins;
          font-style: normal;
          font-weight: 300;
          font-size: 22px;
          line-height: 110%;
          border: 0;
          border-bottom: 1px solid $green-500;
        }

        input::placeholder {
          color: $grey-800;
          opacity: 1; // Firefox
        }
      }

      &-reset {
        height: 54px;
        font-family: Poppins;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        background-color: transparent;
        color: $green-500;
        border: 0;
        cursor: pointer;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 100px;

    @media screen and (min-width: $viewPort-desktop) {
      height: 80vh;
      flex-direction: row;
      margin-bottom: 200px;
    }
  }

  &__providers {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0;

    @media screen and (min-width: $viewPort-desktop) {
      flex: 1;
      flex-flow: row wrap;
      overflow-y: scroll;
      height: 100%;
    }

    &-title {
      margin: 87px 15px 10px 15px;
      border-bottom: 1px solid;
      padding-bottom: 10px;

      @media screen and (min-width: $viewPort-tablet) {
        margin: 40px 50px 22px 50px;
      }

      @media screen and (min-width: $viewPort-desktop) {
        margin: 0 15px 10px 15px;
        width: 100%;
      }
    }
  }

  &__google-maps-wrapper {
    position: relative;
    height: 70vh;
    width: 100%;
    filter: drop-shadow(0px 0px 60px rgba(0, 0, 0, 0.06));

    @media screen and (min-width: $viewPort-desktop) {
      flex: 1;
      height: 100%;
      margin-left: 15px;
      flex-direction: row;
    }

    &.map-hidden {
      height: 70px;
      
      @media screen and (min-width: $viewPort-tablet) {
        height: 150px;
      }
    }
  }

  &__toggle-visibility-button {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(0, 100%);
    z-index: 2;

    @media screen and (min-width: $viewPort-tablet) {
      right: 50px;
      bottom: 20px;
      transform: unset;
    }

    @media screen and (min-width: $viewPort-desktop) {
      display: none;
    }
  }

  &__center-button {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    height: 40px;
    margin: 10px;
    padding: 0px 17px;
    z-index: 1;
    min-width: 200px;

    cursor: pointer;
    background: none padding-box rgb(255, 255, 255);
    border: 0px;
    border-radius: 2px;
    box-shadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px";

    color: rgb(102, 102, 102);
    font-family: Roboto, Arial, sans-serif;
    font-size: 18px;
    font-weight: 500;

    &:hover {
      color: #111111;
    }
  }

  &__your-position-infobox {
    width: 144px;
    position: absolute;
    transform: translate(-50%, -100%);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    // font-family: Poppins;
    // font-style: normal;
    // font-weight: normal;
    // font-size: 13px;
    // line-height: 150%;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    &-container {
      position: relative;
      background-color: white;
      border-radius: 4px;
      padding: 16px 27px;
    }

    &-close-icon {
      position: absolute;
      top: 7px;
      right: 7px;
      cursor: pointer;
      color: #979797;
    }

    &-triangle {
      content: "";
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid white;
    }
  }
}

// Google Maps Autocomplete widget
.pac-container {
  border-radius: 5px;
  width: 319px;

  .pac-item {
    border: 0;
  }

  .pac-item-query {
    font-family: Poppins;
    color: $green-500 !important;
  }
}
