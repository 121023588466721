@import "../../assets/scss/shared";

.get-started-description {
  line-height: 1.7;
  padding: 0 0 40px;
  @media screen and (min-width: $viewPort-tablet) {
    padding: 0 0 50px;
  }
  @media screen and (min-width: $viewPort-desktop) {
    padding: 0 0 100px;
  }

  &__holder {
    @media screen and (min-width: $viewPort-tablet) {
      padding: 0 30px 0 0;
    }
    @media screen and (min-width: $viewPort-desktop) {
      padding: 0 180px 0 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 15px;
    color: $green-500;
    @media screen and (min-width: $viewPort-tablet) {
      margin: 0 0 20px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      margin: 0 0 27px;
    }
  }

  blockquote,
  p {
    margin: 0 0 16px;
    @media screen and (min-width: $viewPort-tablet) {
      margin: 0 0 20px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      margin: 0 0 27px;
    }
  }

  ul {
    list-style: outside;
    margin: 0 0 16px 18px;
    @media screen and (min-width: $viewPort-tablet) {
      margin-bottom: 20px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      margin-bottom: 27px;
    }

    p {
      margin: 0;
    }
  }

  img {
    display: block;
    height: auto !important;
    width: 100%;
    margin: 60px 0 0;
    @media screen and (min-width: $viewPort-tablet) {
      width: calc(100% + 30px);
      margin: 60px -30px 0 0;
    }
    @media screen and (min-width: $viewPort-desktop) {
      width: calc(100% + 180px);
      margin: 100px -180px 0 0;
    }
  }

  .two-columns & {
    padding-bottom: 0;
  }
}
