@import "../../assets/scss/shared";

.pagination {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.145455px;
  color: $green-700;

  display: flex;
  align-items: center;
  height: 100px;
  justify-content: space-between;

  @media screen and (max-width: $viewPort-tablet) {
    align-items: start;
    flex-flow: column;
    margin-top: 24px;

    & pagination__wrapper {
      margin-top: 24px;
    }
  }

  &__button {
    height: 45px;
    width: 45px;
    border: thin solid #dddddd;
    margin-left: 7px;
    color: $green-700;
    text-decoration: none;
    background-color: #fff;
    &:hover {
      background-color: $green-300;
      cursor: pointer;
      text-decoration: none;
    }

    &--disabled {
      background: #dddddd;
      &:hover {
        cursor: not-allowed;
      }
    }

    &--active {
      height: 45px;
      width: 45px;
      margin-left: 7px;
      background-color: $green-500;
      color: #fff;
      border: thin solid $green-500;
      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
    }
  }

  &__icon {
    color: $green-500;
  }

  &__wrapper {
    display: flex;
    margin: 0;
    align-items: center;

    p {
      margin-right: 10px;
    }
  }

  &__listbox {
    position: relative;
    font-size: 15px;
    line-height: 21px;
    letter-spacing: 0.0229162px;
    color: $grey-3;

    &-button {
      cursor: pointer;
      background: #ffffff;
      border: 1px solid #bfc2c4;
      width: 60px;
      height: 32px;
      text-align: left;

      &-icon {
        color: $green-500;
        font-size: 16px;
        line-height: 16px;
        position: absolute;
        right: 12px;
      }
    }

    &-options {
      position: absolute;
      left: 0;
      cursor: pointer;
      width: 100%;
      background-color: white;
      box-shadow: 0px 2px 16px rgba(45, 46, 72, 0.15);
      border-radius: 2px;
      padding: 18px 0;
      z-index: 1;

      li {
        text-align: center;
        width: 100%;
      }
    }
  }
}
