@import "../../assets/scss/shared";

.section-title-menu {
  border-bottom: 1px solid $grey-500;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.25;
  letter-spacing: 0.01em;

  &__list {
    @include container();
    display: flex;
    align-items: flex-start;
    padding: 40px 0 0 10px;
    margin-bottom: -2px;
    overflow-y: hidden;
    overflow-x: auto;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
    -ms-overflow-style: none;
    text-transform: uppercase;

    &::-webkit-scrollbar {
      width: 0;
      background: rgba(255, 255, 255, 0);
    }

    @media screen and (min-width: $viewPort-tablet) {
      padding-left: 65px;
    }

    @media screen and (min-width: $viewPort-desktop) {
      padding: 66px 0 0 110px;
    }

    & > li {
      flex-shrink: 0;

      &:not(:last-child) {
        margin-right: 11px;

        @media screen and (min-width: $viewPort-desktop) {
          margin-right: 22px;
        }
      }

      a {
        padding: 0 5px 4px;
        color: $grey-1000;
        text-decoration: none;

        @media screen and (min-width: $viewPort-tablet) {
          padding: 0 8px 8px;
          font-size: 22px;
          line-height: 1.5;
          letter-spacing: 0.01em;
        }

        @media screen and (min-width: $viewPort-tablet) {
          padding-bottom: 14px;
        }

        &:not(:last-child) {
          margin-right: 11px;

          @media screen and (min-width: $viewPort-tablet) {
            margin-right: 30px;
          }
        }

        &.active-item {
          position: relative;
          display: inline-block;
          font-weight: 600;
          color: $green-400;

          &::after {
            content: "";
            @include position($position: absolute, $bottom: 0, $left: 0);
            width: 100%;
            height: 5px;
            background-color: $green-400;

            @media screen and (min-width: $viewPort-tablet) {
              height: 7px;
            }
          }
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
