@import "../../assets/scss/shared";

.find {
  .section-title {
    padding-bottom: 0 !important;
  }

  .simple_content__holder {
    padding-top: 0;
  }
}
