.two-columns {
  @include container();

  @media screen and (min-width: $viewPort-full-screen) {
    display: flex;
  }

  &__content {
    padding: 0 15px;

    @media screen and (min-width: $viewPort-tablet) {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }
    @media screen and (min-width: $viewPort-full-screen) {
      padding-left: 77px;
      padding-right: 0;
    }
  }

  &__aside {
    @include section(0 15px 60px, 0 0 16px, $black);

    @media screen and (min-width: $viewPort-tablet) {
      width: 341px;
      min-width: 341px;
      padding-left: 50px;
      box-sizing: content-box;
    }

    @media screen and (min-width: $viewPort-full-screen) {
      margin-right: 0;
      padding: 0 30px 0 4vw;
    }
  }

  .page-section_head {
    h1,
    h2,
    h2 {
      color: $green-500;
    }
  }

  &.aside-top {
    .two-columns__aside {
      @media screen and (min-width: $viewPort-full-screen) {
        top: -75px;
        position: relative;
      }
    }
  }

  &.about-page-padding {
    padding-top: 30px;

    @media screen and (min-width: $viewPort-tablet) {
      padding-top: 60px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      padding-top: 120px;
    }
  }

  .about_page_tabs__holder &,
  .already_an_apprentice_tabs__holder & {
    padding-bottom: 20px;
  }
}
