@import "../../assets/scss/shared";

.share {
  color: $green-600;
  display: flex;

  &__title {
    display: inline-block;
    margin: 0 12px 5px 0;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;

    li {
      margin: 0 25px 5px 0;
      &:last-child {
        margin-right: 0;
      }

      button {
        display: flex;
        align-items: center;

        &:hover,
        &:focus {
          .text {
            &:before,
            &:after {
              opacity: 1;
              transform: translateY(0);
            }
            &:before {
              transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
                opacity 0.2s;
            }
            &:after {
              transition: transform 0s 0.2s
                  cubic-bezier(0.175, 0.885, 0.32, 1.275),
                opacity 0s 0.2s;
            }
          }
        }
      }

      svg {
        font-size: 24px;
        margin-right: 7px;
      }
    }

    .text {
      position: relative;

      &:before,
      &:after {
        content: "";
        @include position(
          $position: absolute,
          $bottom: 2px,
          $left: 0,
          $right: 0
        );
        height: 2px;
        background-color: $green-600;
      }
      &:before {
        opacity: 0;
        transform: translateY(-8px);
        transition: transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275),
          opacity 0s;
      }
      &:after {
        opacity: 0;
        transform: translateY(8px/2);
        transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
          opacity 0.2s;
      }
    }
  }
}
