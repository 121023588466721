@import "../../assets/scss/shared";

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(4, 19, 32, 0.65);
  z-index: 10;

  .mask {
    @include position($position: absolute, $top: 0, $left: 0);
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
  }

  iframe {
    width: 347px;
    height: 80%;
    max-height: 195px;
    position: relative;
    z-index: 2;

    @media screen and (min-width: $viewPort-tablet) {
      width: 707px;
      max-height: 398px;
    }

    @media screen and (min-width: $viewPort-desktop) {
      width: 80%;
      max-width: 1088px;
      max-height: 612px;
    }
  }

  & > button {
    @include btn();

    align-self: flex-end;
    margin: 0 14px 24px 0;
    font-weight: 300;
    font-size: 20px;
    line-height: 150%;
    color: $white;
    background-color: transparent;
    transition: transform 0.3s ease;
    transform: rotate(0deg);
    position: relative;
    z-index: 2;

    &:hover {
      transform: rotate(90deg);
    }

    @media screen and (min-width: $viewPort-tablet) {
      margin-right: 28px;
    }

    @media screen and (min-width: $viewPort-tablet) {
      margin-right: 7.5vw;
    }
  }
}
