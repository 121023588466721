@import "../../assets/scss/shared";

// Override react-responsive-carousel legend
.LightBoxGallery {
  &__image {
    object-fit: cover;
    max-height: calc(90vh - 160px);
  }
  .carousel .slide &__name-badge.legend {
    margin-left: -48%;
    opacity: 1;
    position: absolute;
    bottom: 5px;
    width: 97%;
    border-radius: 0px;
    background: rgba(11, 36, 48, 0.45);
    z-index: 1;
    padding: 5px;

    @media screen and (min-width: $viewPort-tablet) {
      margin-left: -48%;
      width: 97%;
      padding: 10px;
      bottom: 20px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      margin-left: -44%;
      width: 88%;
      padding: 26px;
    }
  }

  .carousel .slide &__name-text {
    margin: 0px;
    color: $white;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: left;

    @media screen and (min-width: $viewPort-tablet) {
      font-weight: 400;
      font-size: 22px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      font-weight: normal;
      font-size: 38px;
    }
  }

  .carousel .carousel-status {
    position: absolute;
    top: auto;
    bottom: 36px;
    right: 2px;
    padding: 5px;
    font-size: 16px;
    color: $white;
    text-shadow: none;
    font-weight: lighter;

    @media screen and (min-width: $viewPort-tablet) {
      bottom: 41px;
      right: 12px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      color: $grey-500;
      right: 80px;
      bottom: 0px;
    }
  }

  .carousel .control-dots {
    display: none;
  }

  .carousel .thumbs-wrapper {
    margin: 20px 20px 20px 0px;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #0b2430;
  }

  .thumbs-wrapper .thumb {
    border: none;
    margin: 0px 14px;
    height: 100px;
  }

  .thumbs-wrapper .thumb img {
    width: 180px;
    height: 98px;
    object-fit: cover;
  }

  .thumbs-wrapper .thumb.selected {
    border: none;
  }

  .thumbs-wrapper .thumb.selected img {
    border: 1px solid $red-500;
  }
}
