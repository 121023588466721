@import "../../assets/scss/shared";

.button {
  cursor: pointer;
  margin: 0;
  border: 0;
  outline: none;
  display: block;
  font-size: 14px;
  line-height: 21px;
  color: $grey-3;
  padding: 20px 35px;
  background: $green-300;
}
