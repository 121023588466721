@import "../../assets/scss/shared";

.page-apprenticeship-directory {
  .page-section {
    &.career_seekers_programmes__holder {
      padding: 40px 0 0;

      @media screen and (min-width: $viewPort-tablet) {
        padding-top: 70px;
      }

      @media screen and (min-width: $viewPort-desktop) {
        padding-top: 90px;
      }

      .page-section_head {
        position: relative;
        margin: 0 0 70px;

        @media screen and (min-width: $viewPort-tablet) {
          margin: 0 0 30px;
          margin-bottom: 50px;
        }

        .page-section_head__holder {
          margin: 0;

          @media screen and (min-width: $viewPort-tablet) {
            margin: 0 0 40px;
          }

          h2 {
            font-size: 24px;
            line-height: 1.2;

            @media screen and (min-width: $viewPort-tablet) {
              font-size: 30px;
            }

            @media screen and (min-width: $viewPort-desktop) {
              font-size: 40px;
            }
          }

          .ActionButton {
            @media screen and (max-width: $viewPort-mobile-max) {
              position: absolute;
              bottom: 0;
              right: 18px;
              transform: translatey(100%);
            }
          }
        }

        .page-section_head__description {
          max-width: 750px;
          margin: 0;
        }
      }
    }

    &.soon_programmes__holder {
      @media screen and (min-width: $viewPort-desktop) {
        padding: 100px 0 0;
      }
    }
  }

  .explore-programmes,
  .note-box {
    @media screen and (min-width: $viewPort-tablet) {
      margin-left: 0;
    }
  }

  .skew-top-footer {
    @media screen and (min-width: $viewPort-desktop) {
      margin-top: 220px;
    }
  }
}

.page-section_foot__download-pdf {
  margin: 60px 0 0 0;
}
