@import "../../assets/scss/shared";

.note-box {
  font-size: 16px;
  line-height: 1.7;
  max-width: 644px;
  margin-top: 50px;
  color: $grey-4;
  @media screen and (min-width: $viewPort-tablet) {
    margin-top: 60px;
  }

  h5 {
    margin: 0 0 12px;
  }
}
