@import "../../assets/scss/shared";

.page-section {
  position: relative;

  .homepage &,
  .discover &,
  .parents-friends &,
  .potential-employers &,
  .find &,
  .step-container &,
  .educators &,
  .instructors & {
    padding: 80px 0;

    @media screen and (min-width: $viewPort-desktop) {
      padding: 157px 0;
    }
  }

  &:nth-child(1) {
    z-index: 1;
    margin: 0 0 -5px;

    .skew-main-background {
      height: auto;
      top: -9999px;
      bottom: 0;
    }
  }

  .skew-top-background {
    @include skewLine(transparent);
    height: 53px;
    top: -52px;
    z-index: 2;
    @media screen and (min-width: $viewPort-desktop) {
      height: 75px;
      top: -74px;
    }
  }

  .skew-main-background {
    @include skewLine(transparent);
    height: 100%;
  }

  .skew-bottom-background {
    @include skewLine(transparent);
    height: 53px;
    bottom: -53px;
    top: auto;
    z-index: 2;
    @media screen and (min-width: $viewPort-desktop) {
      height: 75px;
      bottom: -75px;
    }
  }

  &.has-top-background {
    margin-top: 53px;
    @media screen and (min-width: $viewPort-desktop) {
      margin-top: 75px;
    }
  }

  &.has-main-background {
    padding: 80px 0;

    @media screen and (min-width: $viewPort-desktop) {
      padding: 157px 0;
    }

    &:last-child {
      .skew-main-background {
        bottom: -99999px;
        height: auto;
      }
    }
  }

  &.has-bottom-background {
    margin-bottom: 53px;
    @media screen and (min-width: $viewPort-desktop) {
      margin-bottom: 75px;
    }
  }

  &_head {
    padding-bottom: auto;
    margin-bottom: 10px;

    @media screen and (min-width: $viewPort-tablet) {
      margin-bottom: 10px;
    }

    &__holder {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__description {
      margin-top: 20px;
      @media screen and (min-width: $viewPort-tablet) {
        margin-top: 12px;
      }
      @media screen and (min-width: $viewPort-desktop) {
        margin: 14px 0 20px;
      }
    }

    h2 {
      font-size: 20px;
      max-width: 80%;

      @media screen and (min-width: $viewPort-tablet) {
        font-size: 27px;
      }

      @media screen and (min-width: $viewPort-desktop) {
        font-size: 30px;
      }
    }
    a {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.5;
      color: $green-300;
    }
  }
}
