@import "../../../assets/scss/_shared.scss";

.link-double {
  display: inline-block;
  font-size: 14px;
  line-height: 1.5;
  font-weight: normal;
  color: $grey-3;
  cursor: pointer;
  text-decoration: none;
  &::before {
    content: "⟶";
    display: block;
    width: 60px;
    height: 60px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 auto;
    font-size: 16px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }
  &:hover {
    text-decoration: none;
    color: $grey-3;
    .link-double__holder {
      &::after {
        width: 100%;
      }
    }
  }

  &__holder {
    display: flex;
    align-items: center;
    min-width: 180px;
    height: 60px;
    text-align: center;
    padding: 0 20px;
    position: relative;
    z-index: 2;
    opacity: 1 !important;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border: none;
    &::before,
    &::after {
      content: "";
      @include position($position: absolute, $top: 0, $left: 0);
      height: 100%;
      background-color: $green-200;
      width: 0;
      transition: width 0.3s ease;
      z-index: -1;
    }
    &::before {
      background-color: $green-300;
      z-index: -2;
      width: 100%;
      opacity: 1;
    }
  }

  .text {
    position: relative;
    z-index: 2;
  }
}
