@import "../../../assets/scss/shared";

.decoration-square {
  display: block;
  width: 120px;
  height: 120px;
  background: repeating-linear-gradient(
    -45deg,
    $green-300,
    $green-300 1px,
    transparent 1.5px,
    transparent 5px
  );
}
