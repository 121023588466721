@import "../../assets/scss/shared";

.price-list {
  padding: 30px 22px;
  flex: 0 0 276px;
  max-width: 350px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
  margin-left: 40px;
  margin: 20px auto 0;

  @media screen and (min-width: $viewPort-desktop) {
    margin: 0 0 0 40px;
  }

  h5 {
    margin: 0 0 5px;
    color: $green-500;
  }

  table {
    width: 100%;
    font-size: 14px;
    line-height: 1.5;

    tr {
      border-bottom: 1px solid $grey-500;
    }

    td {
      padding: 20px 0 8px;

      &.title {
        color: $green-700;
      }

      &.sum {
        text-align: right;
      }
    }
  }
}
