@import "../../assets/scss/shared";

.heading {
  display: flex;
  align-items: center;

  h2 {
    font-size: 18px;
    line-height: 1.25;
    margin: 0;
    @media screen and (min-width: $viewPort-tablet) {
      font-size: 26px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      font-size: 30px;
    }
  }

  &__right {
    margin: 0 0 0 auto;
    white-space: nowrap;
    padding: 0 0 0 15px;

    a {
      color: $green-300;
      font-size: 14px;
      line-height: 1.5;

      svg {
        margin: 0 0 0 4px;
        position: relative;
        left: 0;
        transition: left 0.3s ease;
        position: relative;
        top: 1px;
      }

      &:hover {
        svg {
          left: 3px;
        }
      }
    }
  }
}
