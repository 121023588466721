/* ------------------------------------------------------------ *\
  Viewport variables
\* ------------------------------------------------------------ */
$viewPort-mobile: "414px";
$viewPort-mobile-max: "767px";
$viewPort-tablet: "768px";
$viewPort-desktop: "1024px";
$viewPort-full-screen: "1200px";

/* ------------------------------------------------------------ *\
  Colours
\* ------------------------------------------------------------ */
/* Primary Colours */
$white: #fff;
$black: #000;
$grey-1: #333;
$grey-2: #f3f4f5;
$grey-3: #041320;
$grey-4: #2d2d2d;
$grey-300: #e8e8e8;
$grey-500: #bfc2c4;
$grey-800: #8f9294;
$grey-1000: #020c14;
$light-green: #72e7c9;

/* Brand Colours */
$green-100: #d8fffd;
$green-200: #7cd8db;
$green-300: #70c6c8;
$green-400: #47878e;
$green-500: #316d74;
$green-600: #2d414a;
$green-700: #102f3a;
$green-800: #0b2430;
$green-900: #071a27;
$green-1000: #173c44;

$red-500: #ff005d;

/* Category Colours */
$auctioneering: #4baec5;
$engineering: #ed028c;
$biopharmchem: #047e40;
$construction: #00abec;
$electrical: #7d72b4;
$finance: #fbb61e;
$hospFood: #f26221;
$technology: #f47aaf;
$motor: #6e9a34;
$logistics: #de2b31;
$sales: #00b2b9;
$hairdressing: #ef4136;

$inner-orange: #be5728;
$blue: #0274be;
$red-error: #ff6f6f;

:export {
  black: $black;
  white: $white;
  grey-1: $grey-1;
  grey-2: $grey-2;
  grey-3: $grey-3;
  grey-4: $grey-4;
  grey-300: $grey-300;
  grey-500: $grey-500;
  grey-1000: $grey-1000;

  green-100: $green-100;
  green-200: $green-200;
  green-300: $green-300;
  green-400: $green-400;
  green-500: $green-500;
  green-600: $green-600;
  green-700: $green-700;
  green-800: $green-800;
  green-900: $green-900;
  green-1000: $green-1000;

  red-500: $red-500;

  auctioneering: $auctioneering;
  engineering: $engineering;
  biopharmchem: $biopharmchem;
  construction: $construction;
  electrical: $electrical;
  finance: $finance;
  hospFood: $hospFood;
  technology: $technology;
  motor: $motor;
  logistics: $logistics;
  sales: $sales;
  hairdressing: $hairdressing;

  inner-orange: $inner-orange;
  blue: $blue;
  red-error: $red-error;
}

/* ------------------------------------------------------------ *\
  Mixins
\* ------------------------------------------------------------ */
@mixin bg-color($color) {
  background-color: $color;
}

@mixin explore-cards-color($color) {
  background: linear-gradient(-168deg, rgba(0, 0, 0, 0) 36%, $color 36.2%);

  &:hover {
    background: linear-gradient(
      -168deg,
      rgba(0, 0, 0, 0) 15%,
      $green-800 15.2%
    );
  }
}

@mixin decor-stripes-color($color) {
  background-image: repeating-linear-gradient(
    -45deg,
    $color,
    $color 1px,
    transparent 1.5px,
    transparent 8px
  );
}

@mixin container() {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
}

@mixin decor-sloping-line($color) {
  width: 100%;
  height: 45px;
  background-color: $color;
  transform: skew(0deg, -6deg);

  @media screen and (min-width: $viewPort-tablet) {
    height: 55px;
  }

  @media screen and (min-width: $viewPort-desktop) {
    height: 75px;
  }
}

@mixin section($padding, $h2-margin, $h2-color) {
  padding: $padding;

  h2 {
    font-size: 20px;
    line-height: 1.5;
    margin: $h2-margin;
    color: $h2-color;

    @media screen and (min-width: $viewPort-tablet) {
      font-size: 26px;
    }

    @media screen and (min-width: $viewPort-desktop) {
      font-size: 30px;
    }
  }
}

@mixin btn() {
  font-family: Poppins, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 400;
  border: none;
  outline: none;
  cursor: pointer;
}

@mixin links($color) {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  text-decoration: none;
  color: $color;
}

@mixin hoverLinks() {
  &:hover {
    text-decoration: underline;
  }
}

/// Shorthand mixin for offset positioning
@mixin position(
  $position,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null
) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

/// Shorthand mixin for Skew Line
$skewXDesktop: -5.4deg;
$skewXMobile: -6.6deg;

@mixin skewLine($color) {
  @include position(absolute, $top: 0, $right: 0, $left: 0);
  content: "";
  z-index: -1;
  transform: skewY($skewXMobile);
  background-color: $color;

  @media screen and (min-width: $viewPort-desktop) {
    transform: skewY($skewXDesktop);
  }
}

// Card Components //
$card-width: 300px;
$card-height: 360px;

// Photo Components //
$photo-width: 280px;
