@import "../../assets/scss/shared";

.explore-programmes {
  margin: 0 0 -48px;
  color: $white;

  @media screen and (min-width: $viewPort-tablet) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -10px -46px;
  }

  @media screen and (min-width: $viewPort-desktop) {
    margin: 0 -20px -76px;
  }

  @media screen and (min-width: 1420px) {
    justify-content: flex-start;
  }

  &__item {
    max-width: 400px;
    margin: 0 auto 48px;
    position: relative;
    border-right: 18px solid $white;
    @media screen and (min-width: $viewPort-tablet) {
      border-right: none;
      flex: 270px 0 0;
      margin: 0 10px 46px;
      min-width: 270px;
    }
    @media screen and (min-width: $viewPort-full-screen) {
      max-width: 100%;
      flex: calc(25% - 40px) 0 0;
      max-width: calc(25% - 40px);
      margin: 0 20px 76px;
    }

    &.soon-program {
      background-color: $green-800 !important;
      .explore-programmes__frame {
        height: 373px;
        @media screen and (min-width: $viewPort-tablet) {
          height: 401px;
        }
      }
      .mask {
        top: 12%;
        background-color: $green-700 !important;
        @media screen and (min-width: $viewPort-desktop) {
          top: 9%;
        }
      }

      .explore-programmes__modal-panel {
        top: 0;
        opacity: 1;
      }

      .decoration-square {
        z-index: 3;
      }
    }

    @at-root body:not(.isMobile) .explore-programmes__item:hover,
      .explore-programmes__item.active-program {
      .explore-programmes__frame::before {
        left: -100%;
      }
      .explore-programmes__visual,
      .explore-programmes__bottom-panel {
        opacity: 0;
      }
      .mask {
        top: 12%;
        background-color: $green-700 !important;
        @media screen and (min-width: $viewPort-desktop) {
          top: 9%;
        }
      }

      .explore-programmes__modal-panel {
        top: 0;
        opacity: 1;
        transition: all 0.2s ease 0.1s;
      }

      .decoration-square {
        z-index: 3;
      }
    }

    @at-root body .explore-programmes__item.isExpanded,
      .explore-programmes__item.active-program {
      .explore-programmes__frame::before {
        left: -100%;
      }
      .explore-programmes__visual,
      .explore-programmes__bottom-panel {
        opacity: 0;
      }
      .mask {
        top: 12%;
        background-color: $green-700 !important;
        @media screen and (min-width: $viewPort-desktop) {
          top: 9%;
        }
      }

      .explore-programmes__modal-panel {
        top: 0;
        opacity: 1;
        transition: all 0.2s ease 0.1s;
      }

      .decoration-square {
        z-index: 3;
      }
    }
  }

  &__frame {
    position: relative;
    overflow: hidden;
    &::before {
      content: "";
      @include position($position: absolute, $top: 0, $left: 0);
      width: 100%;
      height: 100%;
      z-index: 5;
      opacity: 0;
      background-color: $white;
      transition: all 0.2s ease 0.1s;
    }
  }

  &__visual {
    position: relative;
    padding-top: 78%;
    display: block;
    z-index: 0;
    transition: opacity 0.2s ease;

    @media screen and (min-width: $viewPort-tablet) {
      padding-top: 106%;
    }

    @media screen and (min-width: $viewPort-full-screen) {
      padding-top: 106%;
    }

    &::before {
      content: "";
      background: linear-gradient(
        233.13deg,
        rgba(7, 26, 39, 0.9) 5.32%,
        rgba(7, 26, 39, 0.43) 90.28%
      );
      z-index: 1;
      @include position($position: absolute, $top: 0, $left: 0);
      width: 100%;
      height: 100%;
    }

    img {
      @include position($position: absolute, $top: 0, $left: 0);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .decoration-letter {
      max-width: 83px;
      top: 52px;

      @media screen and (min-width: $viewPort-tablet) {
        max-width: none;
        top: 30px;
      }
    }
  }

  &__bottom-panel {
    position: relative;
    padding: 0 14px 41px;
    z-index: 2;
    color: $white;
    opacity: 1;
    transition: opacity 0.2s ease;
    @media screen and (min-width: $viewPort-tablet) {
      padding: 0 20px 36px;
    }

    h5 {
      margin: 0 0 28px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $white;
      font-size: 22px;
      line-height: 1.5;
      @media screen and (min-width: $viewPort-tablet) {
        margin: 0 0 20px;
      }
      @media screen and (min-width: $viewPort-full-screen) {
        margin: 0 0 25px;
      }
    }

    .link-secondary {
      margin: 0 0 0 8px;
      @media screen and (min-width: $viewPort-tablet) {
        margin: 0;
      }
      &::after {
        margin: 0 0 0 27px;
        font-size: 18px;
      }
    }
  }

  .mask {
    @include position($position: absolute, $top: 0, $left: 0);
    z-index: 1;
    width: 100%;
    height: 600px;
    transform: skewY(12deg);
    transition: top 0.4s ease, background 0.4s ease;
    top: 53%;

    @media screen and (min-width: $viewPort-tablet) {
      top: 63%;
    }
  }

  &__modal-panel {
    @include position($position: absolute, $top: 20px, $left: 0, $right: 0);
    z-index: 3;
    height: 100%;
    padding: 70px 22px 0;
    font-size: 14px;
    line-height: 1.5;
    transition: all 0.2s ease 0s;
    opacity: 0;

    @media screen and (min-width: $viewPort-tablet) {
      padding: 56px 20px 0;
    }

    h5 {
      color: $white;
      font-size: 22px;
      line-height: 1.5;
      margin: 0 0 10px;
      @media screen and (min-width: $viewPort-tablet) {
        margin: 0 0 7px;
      }
    }

    .link-secondary {
      &::before {
        content: "+";
        margin: 0 6px 0 0;
        font-size: 22px;
        position: relative;
        left: 0;
        top: 3px;
        transition: left 0.3s ease;
      }
      &::after {
        display: none;
      }

      &:hover {
        &::before {
          left: -3px;
        }
      }
    }
  }

  &__description {
    height: 198px;
    overflow: hidden;
    position: relative;
    mask-image: linear-gradient(
      to top,
      transparent,
      $green-700 20px,
      $green-700 100%,
      transparent
    );
    transition: height 0.2s ease;
    @media screen and (min-width: $viewPort-tablet) {
      height: 211px;
      margin: 0 0 8px;
    }

    &.scroll-active {
      overflow: auto;
      height: 240px;
      @media screen and (min-width: $viewPort-tablet) {
        height: 250px;
      }
    }

    .frame {
      padding-bottom: 20px;
    }

    ul {
      li {
        margin: 0 0 10px;
      }
    }

    p {
      margin: 0 0 10px;
    }

    a {
      color: $white;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .decoration-square {
    @include position($position: absolute, $right: -14px, $bottom: -16px);
    width: 80px !important;
    height: 80px !important;
    right: -18px;
    bottom: -19px;

    @media screen and (min-width: $viewPort-tablet) {
      width: 66px !important;
      height: 66px !important;
      right: -14px;
      bottom: -16px;
    }
  }
}

.has-main-background {
  .explore-programmes__item {
    border-right: 18px solid $green-600;
    @media screen and (min-width: $viewPort-tablet) {
      border-right: none;
    }
  }
}

.programmes__holder,
.soon_programmes__holder {
  .note-box {
    padding-top: 70px;
    @media screen and (min-width: $viewPort-tablet) {
      padding-top: 40px;
    }

    a {
      color: $red-500;
    }
  }
}
