@import "../../assets/scss/shared";

.loader {
  position: relative;
  width: auto;
  height: auto;

  &.small-size {
    div {
      height: 6px;
      width: 6px;
    }

    & > div:nth-child(1) {
      top: -11px;
      left: 0;
    }
    & > div:nth-child(2) {
      top: -8px;
      left: 8px;
      -webkit-animation-delay: 0.1s;
      animation-delay: 0.1s;
    }
    & > div:nth-child(3) {
      top: 0;
      left: 11px;
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s;
    }
    & > div:nth-child(4) {
      top: 8px;
      left: 8px;
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
    }
    & > div:nth-child(5) {
      top: 11px;
      left: 0;
      -webkit-animation-delay: 0.4s;
      animation-delay: 0.4s;
    }
    & > div:nth-child(6) {
      top: 8px;
      left: -8px;
      -webkit-animation-delay: 0.5s;
      animation-delay: 0.5s;
    }
    & > div:nth-child(7) {
      top: 0;
      left: -11px;
      -webkit-animation-delay: 0.6s;
      animation-delay: 0.6s;
    }
    & > div:nth-child(8) {
      top: -8px;
      left: -8px;
      -webkit-animation-delay: 0.7s;
      animation-delay: 0.7s;
    }
  }

  div {
    height: 10px;
    width: 10px;
    background-color: $grey-300;
    border-radius: 50%;
    position: absolute;
    -webkit-animation: 0.8s opaque ease-in-out infinite both;
    animation: 0.8s opaque ease-in-out infinite both;
  }

  & > div:nth-child(1) {
    top: -25px;
    left: 0;
  }
  & > div:nth-child(2) {
    top: -17px;
    left: 17px;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
  & > div:nth-child(3) {
    top: 0;
    left: 25px;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  & > div:nth-child(4) {
    top: 17px;
    left: 17px;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  & > div:nth-child(5) {
    top: 25px;
    left: 0;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  & > div:nth-child(6) {
    top: 17px;
    left: -17px;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  & > div:nth-child(7) {
    top: 0;
    left: -25px;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  & > div:nth-child(8) {
    top: -17px;
    left: -17px;
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
  }
}

@-webkit-keyframes opaque {
  0% {
    opacity: 0.1;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes opaque {
  0% {
    opacity: 0.1;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.1;
  }
}

.main-loader {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: $viewPort-tablet) {
    height: 400px;
  }

  @media screen and (min-width: $viewPort-desktop) {
    height: 500px;
  }
}
