@import "../../assets/scss/shared";

.features-list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  color: $white;
  border-radius: 5px;
  overflow: hidden;

  @media screen and (min-width: $viewPort-tablet) {
    &::after {
      content: "";
      @include position($position: absolute, $top: 50%, $left: 50%);
      width: 70px;
      height: 70px;
      background-image: repeating-linear-gradient(
        -45deg,
        $green-300,
        $green-300 1px,
        transparent 1.5px,
        transparent 8px
      );
      transform: translate(-50%, -50%);
    }
  }

  @media screen and (min-width: $viewPort-tablet) {
    &::after {
      width: 120px;
      height: 120px;
    }
  }

  & > li {
    width: 100%;
    padding: 39px 35px 51px;

    &:hover {
      position: relative;
      z-index: 1;
    }

    @media screen and (min-width: $viewPort-tablet) {
      width: 50%;
      position: relative;
      min-height: 430px;
      padding-bottom: 70px;
      padding: 39px 28px 51px;
    }

    @media screen and (min-width: $viewPort-desktop) {
      padding: 64px 38px 83px;
    }

    strong {
      display: block;
      font-weight: bold;
      margin-bottom: 11px;
      font-size: 26px;
      line-height: 1.3;
      letter-spacing: 0.01em;

      @media screen and (min-width: $viewPort-desktop) {
        font-size: 32px;
      }
    }

    p {
      margin: 0 0 43px;
      font-size: 16px;
      line-height: 1.7;
    }

    .link-block {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 9px;

      @media screen and (min-width: $viewPort-desktop) {
        justify-content: flex-end;
      }

      a {
        font-weight: 500;
        font-size: 14px;
        line-height: 1.5;
        text-decoration: none;
        color: $white;

        span {
          display: inline-block;
          margin-left: 20px;

          @media screen and (min-width: 375px) {
            margin-left: 28px;
          }
        }
      }
    }
  }
}
