@import "../../../assets/scss/fonts";
@import "../../../assets/scss/shared";

.ProviderCard {
  padding: 7px 20px 32px 32px;
  margin: 10px 20px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  
  @media screen and (min-width: $viewPort-tablet) {
    margin: 16px 50px;
  }

  @media screen and (min-width: $viewPort-desktop) {
    margin: 15px;
    width: 100%;
  }

  &.selected {
    background-color: #f2f2f2;
    box-shadow: none;
  }

  h5 {
    color: $grey-3;
    margin: 0 0 10px;
  }

  &__distance {
    color: $green-700;
    font-size: 14px;
    line-height: 170%;
    font-style: italic;
    font-weight: 600;
    text-align: right;
  }

  &__address {
    color: $green-700;
    font-style: normal;
    position: relative;
    margin: 0 0 10px;
    padding: 0 0 0 28px;
    background: url("../../../assets/img/svg/icon-house.svg") no-repeat 0 5px;
  }

  &__info-row {
    overflow: hidden;

    &__phone {
      float: left;
      color: $grey-1000;
      margin: 0 30px 0 0;
      padding: 0 0 0 28px;
      background: url("../../../assets/img/svg/icon-phone.svg") no-repeat 0 50%;
    }

    &__website {
      float: left;
      color: $green-500;
      padding: 0 0 0 28px;
      background: url("../../../assets/img/svg/icon-chain.svg") no-repeat 0 50%;
    }
  }
}
