@import "../../../assets/scss/shared";

.yt-video {
  margin: 30px auto;
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  @media screen and (min-width: $viewPort-desktop) {
    padding-top: calc(56.25% + 180px * 0.5625);
    width: calc(100% + 180px);
  }
}
.yt-video .yt-player {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
