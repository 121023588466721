@import "../../assets/scss/shared";
@import "../CareerSeekersPages/LearnMorePage.scss";

.apply {
  .two-columns__aside {
    display: none;
    @media screen and (min-width: $viewPort-desktop) {
      display: block;
    }
  }

  .get-started-description {
    a {
      color: $red-500;
    }
  }
}
