@import "./shared";

h1,
.h1,
h2,
.h2,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 600;
  letter-spacing: 0.01em;
  color: $grey-1;
}

h1,
.h1 {
  font-size: 28px;
  line-height: 1.25;

  @media screen and (min-width: $viewPort-tablet) {
    font-size: 42px;
  }

  @media screen and (min-width: $viewPort-desktop) {
    font-size: 55px;
  }
}

h2,
.h2 {
  font-size: 24px;
  line-height: 1.3;

  @media screen and (min-width: $viewPort-tablet) {
    font-size: 30px;
  }

  @media screen and (min-width: $viewPort-desktop) {
    font-size: 40px;
  }
}

h3,
.h3 {
  font-size: 22px;
  line-height: 1.3;

  @media screen and (min-width: $viewPort-tablet) {
    font-size: 28px;
  }

  @media screen and (min-width: $viewPort-desktop) {
    font-size: 32px;
  }
}

h4,
.h4 {
  font-size: 20px;
  line-height: 1.5;

  @media screen and (min-width: $viewPort-tablet) {
    font-size: 26px;
  }

  @media screen and (min-width: $viewPort-desktop) {
    font-size: 30px;
  }
}

h5,
.h5 {
  font-size: 18px;
  line-height: 1.25;

  @media screen and (min-width: $viewPort-tablet) {
    font-size: 22px;
    line-height: 1.5;
  }
}

h6,
.h6 {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-weight: normal;
}

a {
  color: $blue;

  &:hover {
    text-decoration: underline;
  }
}
