@import "../../../assets/scss/_shared.scss";

.link-secondary {
  display: inline-block;
  font-size: 14px;
  line-height: 1.5;
  font-weight: normal;
  color: $green-500;
  cursor: pointer;

  &:hover {
    text-decoration: none;

    .text {
      text-decoration: underline;
    }

    &:after {
      left: 3px;
    }
  }

  &:after {
    content: "⟶";
    margin: 0 0 0 11px;
    position: relative;
    transition: left 0.3s ease;
    left: 0;
  }
}
