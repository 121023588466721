@import "../../../assets/scss/shared";
.ActionButton {
  @include btn();
  display: inline-block;
  position: relative;
  overflow: hidden;
  padding: 16px 14px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  background-color: $green-300;
  color: $grey-3;
  transition: border 0.3s ease, background 0.3s ease, color 0.3s ease;
  white-space: nowrap;
  &::before {
    content: "";
    @include position($position: absolute, $top: 0, $left: 0);
    height: 100%;
    background-color: $white;
    width: calc(100% - 44px);
    transition: width 0.3s ease, opacity 0.3s ease;
  }

  &:hover {
    text-decoration: none;
    &::before {
      width: 0;
    }
  }
  &__text {
    position: relative;
    z-index: 1;
  }
  &__icon {
    position: relative;
    z-index: 1;
    margin-left: 44px;
    font-size: 18px;
    transition: color 0.3s ease;
    position: relative;
    z-index: 1;
  }
  &__svg {
    width: 17px;
    text-align: center;
  }
}
