@import "../../assets/scss/shared";

.cookie-policy {
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background: $grey-3;
  color: $white;
  font-size: 14px;
  line-height: 21px;

  @media screen and (min-width: $viewPort-tablet) {
    padding: 30px;
  }

  p {
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .area {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media screen and (min-width: $viewPort-tablet) {
      flex-direction: row;
    }

    .policy-content {
      margin: 0 0 20px;

      @media screen and (min-width: $viewPort-tablet) {
        margin: 0;
      }
    }

    .aside {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      @media screen and (min-width: $viewPort-tablet) {
        margin: 0 0 0 100px;
      }

      > a:not(:last-child) {
        padding: 10px 20px 10px 0;

        @media screen and (min-width: $viewPort-tablet) {
          padding: 0;
          margin: 0 50px 0 0;
        }
      }

      .button {
        padding-left: 10px;
        padding-right: 10px;

        @media screen and (min-width: $viewPort-tablet) {
          padding-left: 35px;
          padding-right: 35px;
        }
      }
    }
  }
}
