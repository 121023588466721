@import "../../assets/scss/shared";

.hero-slider {
  transform: skewY(11deg);
  margin: -214px -20px 66px -300px;
  @media screen and (min-width: $viewPort-tablet) {
    margin: -268px -70px 114px -700px;
  }
  @media screen and (min-width: $viewPort-desktop) {
    margin: -317px -90px -19.5%;
  }
  &::before {
    content: "";
    @include position(
      $position: absolute,
      $top: -999px,
      $bottom: 0,
      $left: -9999px,
      $right: -9999px
    );
    background-color: $red-500;
  }

  &__holder {
    position: relative;
    overflow: hidden;
    z-index: 1;
    margin: 0;
    padding: 40px 0 0;
    display: flex;
    justify-content: flex-end;
    &::before {
      @media screen and (min-width: $viewPort-tablet) {
        content: "";
        width: 430px;
        @include position(
          $position: absolute,
          $top: 0,
          $bottom: -21%,
          $right: 405px
        );
        z-index: 1;
        transform: skew(-11deg) rotate(-12deg);
        background: url(../../assets/img/HeroSlider/decoration.png) no-repeat
          100% 100%;
        background-size: 100% auto;
      }
      @media screen and (min-width: $viewPort-desktop) {
        bottom: -109px;
        right: 368px;
      }
    }
  }

  .slick-slider {
    margin: 0 0 -11%;
  }

  &__list {
    margin: 0 23px 0 auto;
    display: flex;
    position: relative;
    z-index: 1;
    @media screen and (min-width: $viewPort-tablet) {
      margin-right: 64px;
    }
    @media screen and (min-width: $viewPort-desktop) {
      margin-right: 41px;
    }

    li {
      margin: 0 6px;
      width: 60px;
      height: 217px;
      position: relative;
      overflow: hidden;
      transform: skewX(11deg);

      &:before {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        mix-blend-mode: multiply;
        z-index: 1;
      }

      &:nth-child(1) {
        &:before {
          background: #cd9c30;
        }
      }

      &:nth-child(2) {
        &:before {
          background: #6e9a34;
        }
      }

      &:nth-child(3) {
        &:before {
          background: #5d4bb9;
        }
      }

      @media screen and (min-width: $viewPort-tablet) {
        margin: 0 12px;
        width: 100px;
        height: 458px;
      }
      @media screen and (min-width: $viewPort-desktop) {
        margin: 0 12px;
        width: 100px;
        height: 426px;
      }
    }

    img {
      position: absolute;
      top: 0;
      height: 106%;
      left: -100%;
      width: 300%;
      display: block;
      object-fit: cover;
      position: relative;
      transform: skew(-20deg) rotate(-11deg);
      @media screen and (min-width: $viewPort-desktop) {
        height: 105%;
      }
    }
  }
}
