@import "../../assets/scss/shared";

.stories-list {
  padding: 10px 10px 0 0;
  margin: 0 -10px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;

  @media screen and (min-width: $viewPort-tablet) {
    flex-direction: row;
    align-items: flex-start;
  }

  &__link {
    @include position($position: absolute, $top: 35px, $right: 23px);
    font-size: 14px;
    line-height: 1.5;
    text-decoration: none;
    color: $white;
    z-index: 4;

    @media screen and (min-width: $viewPort-tablet) {
      top: 23px;
      right: 15px;
      font-size: 12px;
    }

    @media screen and (min-width: $viewPort-desktop) {
      font-size: 14px;
    }

    span {
      display: inline-block;
      margin-left: 10px;

      @media screen and (min-width: $viewPort-tablet) {
        display: none;
      }

      @media screen and (min-width: $viewPort-mobile) {
        display: inline-block;
      }
    }
  }

  & > li {
    position: relative;
    width: 100%;
    height: 20vw;
    min-width: 280px;
    min-height: 425px;

    &:before {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.3);
    }

    &:not(:last-child) {
      margin-bottom: 25px;
      overflow: hidden;
    }

    a {
      display: block;
      height: 100%;
    }

    img {
      height: 100%;
      width: auto;
      max-width: 100%;
    }

    @media screen and (min-width: $viewPort-mobile) {
      min-width: 0;
      min-height: 0;
      width: 390px;
      height: 590px;
    }

    @media screen and (min-width: $viewPort-tablet) {
      max-width: 100%;
      max-height: 100%;
      width: 220px;
      height: 334px;

      &:not(:last-child) {
        margin-bottom: 0;
        overflow: visible;
      }
    }

    @media screen and (min-width: $viewPort-desktop) {
      width: 260px;
      height: 395px;
    }

    @media screen and (min-width: $viewPort-full-screen) {
      width: 325px;
      height: 494px;

      &::after {
        content: "";
        @include position($position: absolute, $top: 0, $left: 0);
        box-shadow: 1px 0 $red-500, 1px -1px $red-500, 2px -1px $red-500,
          2px -2px $red-500, 3px -2px $red-500, 3px -3px $red-500,
          4px -3px $red-500, 4px -4px $red-500, 5px -4px $red-500,
          5px -5px $red-500, 6px -5px $red-500, 6px -6px $red-500,
          7px -6px $red-500, 7px -7px $red-500, 8px -7px $red-500,
          8px -8px $red-500, 9px -8px $red-500, 9px -9px $red-500,
          10px -9px $red-500, 10px -10px $red-500, 11px -10px $red-500,
          11px -11px $red-500, 12px -11px $red-500, 12px -12px $red-500;
        width: 100%;
        height: 100%;
        transform: scale(0.9);
        transform-origin: left bottom;
        z-index: -1;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s, transform 0.3s, visibility 0.3s;
      }

      &:hover {
        &:after {
          transform: scale(1);
          visibility: visible;
          opacity: 1;
          transition: opacity 0.3s, transform 0.3s, visibility 0.3s;
        }
      }
    }

    .story-description {
      @include position($position: absolute, $bottom: 10px, $left: 50%);
      transform: translateX(-50%);
      width: 92%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-bottom: 26px;
      padding: 0 25px 3%;
      font-weight: 300;
      font-size: 22px;
      line-height: 1.5;
      color: $white;
      background-image: url(../../assets/img/SuccessStories/story-border.svg);
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: 100%;
      z-index: 3;

      @media screen and (min-width: 400px) {
        padding-bottom: 10%;
      }

      @media screen and (min-width: $viewPort-tablet) {
        margin-bottom: 10px;
        padding: 0 17px 1.5vw;
        font-weight: normal;
        font-size: 16px;
        line-height: 1.4;
      }

      @media screen and (min-width: 1200px) {
        padding: 0 19px 1.5vw;
        font-size: 22px;
      }
    }
  }
}
