@import "../../assets/scss/shared";

.job-detail {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 170%;
  position: relative;

  &__header {
    @include container();
    position: relative;
    padding: 30px 0 0 0;

    @media screen and (min-width: $viewPort-desktop) {
      padding: 60px 0 120px 0;
      display: flex;
    }

    &:after {
      content: "";
      position: absolute;
      background-color: $red-500;
      height: 60px;
      left: -100vw;
      right: -100vw;
      width: 300vw;
      bottom: 120px;
      transform: rotate(-5deg);
      z-index: -1;
    }
  }

  a {
    color: $red-500;
  }

  h1 {
    font-weight: 600;
    font-size: 40px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: $red-500;
  }

  h2 {
    font-weight: 600;
    font-size: 30px;
    line-height: 150%;
    color: $grey-3;
  }

  h3 {
    font-weight: 300;
    font-size: 32px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: $green-400;
  }

  h4 {
    font-weight: bold;
    font-size: 12px;
    line-height: 125%;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: $green-500;
  }

  &__description {
    white-space: pre-wrap;

    ul {
      // revert original browser styles for bullet lists
      list-style-type: disc;
      padding-inline-start: 40px;
    }
  }

  address {
    font-style: normal;
  }

  li {
    line-height: 24px;
  }

  &__green-icon {
    color: $green-500;
  }

  &__container-wrapper {
    @include container();
    padding-bottom: 40px;
  }

  &__address-list {
    padding-bottom: 10px;
    padding-top: 6px;
  }

  &__contact-list {
    li {
      line-height: 45px;
    }

    svg {
      margin-right: 4px;
    }
  }

  &__info-list {
    li {
      line-height: 45px;
    }

    svg {
      margin-left: 4px;
    }
  }

  &__contact-card {
    z-index: 1;
    background: #fff;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
    margin: 0 16px;
    padding: 40px 60px;

    @media screen and (min-width: $viewPort-desktop) {
      padding: 40px 60px;
      width: 580px;
    }
  }

  &__contact-label {
    span:last-child {
      font-size: 16px;
      color: $grey-3;
      font-weight: 400;
    }

    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0;
    margin-bottom: 10px;
  }

  &__contact-label-label {
    font-size: 12px;
    font-weight: lighter;
    text-transform: uppercase;
    font-weight: 200;
  }

  &__back-bottom {
    margin: 40px 0 30px;
  }

  &__container {
    @include container();
    padding: 50px 0;

    @media screen and (min-width: $viewPort-desktop) {
      padding: 80px 0;
    }
  }

  .link-button {
    color: $grey-3;
    width: 100%;
    text-align: center;
    margin-top: 24px;
  }
}
