@import "../../assets/scss/shared";

.page-section {
  &.already_an_apprentice_tabs__holder,
  &.about_page_tabs__holder {
    .container {
      max-width: none;
      padding: 0;
    }
  }
}
