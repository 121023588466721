@import "../../assets/scss/shared";

.user-main-menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;

  & > li {
    display: flex;
    align-items: center;
    padding: 10px 24px;
    font-size: 15px;
    line-height: 1.1;

    a {
      font-weight: 100;
      color: $white;
      text-decoration: none;
    }

    span {
      font-weight: 100;
      cursor: pointer;
    }

    .active,
    .active-item {
      font-weight: 600;
    }

    &.submenu-item {
      position: relative;

      &::before,
      &::after {
        display: none;
        content: "";
        @include position($position: absolute, $left: 50px);
        transform: translateX(-50%);
        z-index: 101;
      }

      &::before {
        bottom: -35px;
        width: 80%;
        height: 2px;
        background-color: $green-200;
      }

      &::after {
        bottom: -45px;
        border: 5px solid transparent;
        border-top-color: $green-200;
      }

      &.submenu-open {
        &::before,
        &::after {
          display: initial;
        }

        svg {
          transform: rotate(180deg);
        }
      }

      svg {
        width: 12px;
        height: 12px;
        margin-left: 12px;
        fill: $white;
        transition: transform 0.3s ease;
      }

      .main-sub-menu__wrapper {
        @include position($position: absolute, $top: 69px, $left: -100vw);
        min-height: 315px;
        width: 200vw;
        border-top: 2px solid $green-500;
        z-index: 99;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.3s;

        &.submenu-open {
          visibility: visible;
          opacity: 1;
        }
      }

      .main-sub-menu {
        @include position($position: absolute, $top: 143px, $left: 0);
        min-width: 250px;
        height: 210px;
        font-size: 14px;
        font-weight: 300;
        z-index: 100;
        visibility: hidden;
        opacity: 0;
        overflow-y: auto;
        transition: visibility 0s, opacity 0.3s;

        &.submenu-open {
          visibility: visible;
          opacity: 1;
        }

        & > li {
          line-height: 1.5;

          &:not(:last-child) {
            margin-bottom: 12px;
          }

          &:first-child {
            text-transform: uppercase;
            color: #d9dadb;
          }
        }
      }
    }
  }
}
