@import "../../assets/scss/shared";

.simple-content {
  ul {
    // revert original browser styles for bullet lists
    list-style-type: disc;
    padding-inline-start: 40px;
  }

  a {
    color: $red-500;

    &.link-default {
      // blue buttons, keep original color
      color: unset;
    }
  }

  p {
    min-height: calc(1.68 * 1em); // so that empty paragraphs take at least some space
  }
}

.page-section.simple_content__holder {
  padding: 50px 0;

  @media screen and (min-width: $viewPort-desktop) {
    padding: 80px 0;
  }

  h2 {
    margin: 0 0 40px;
  }

  p {
    margin: 0 0 30px;
  }

  img {
    width: auto;
    max-width: 100%;

    @media screen and (min-width: $viewPort-desktop) {
      max-width: none;
    }
  }
}

.simple-content__small-size {
  max-width: 750px;
}
