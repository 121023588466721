@import "../../assets/scss/shared";

.social-networks__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 32px;

  @media screen and (min-width: $viewPort-tablet) {
    font-size: 50px;
  }

  & > li {
    margin-bottom: 5px;

    &:not(:last-child) {
      margin-right: 20px;
    }

    a {
      display: flex;
      align-items: center;
      color: $white;
      text-decoration: none;

      &:hover {
        animation: bounce 0.8s ease-in;
        animation-iteration-count: 1;
      }
    }

    .social-title {
      display: inline-block;
      margin-left: 8px;
      font-size: 16px;
    }
  }
}
